import {Observable} from "rxjs";
import {EventModel, EventNetwork, GetAllEventListResponse} from "../network/EventNetwork";
import moment from "moment";
import 'moment/locale/th'
import {EXMParticipantEntity} from "../network/ParticipantNetwork";
import {mapParticipantModelToTableDisplay} from "./ParticipantUseCase";

export class EventUseCase {
    constructor(private network: EventNetwork = new EventNetwork()) {
    }

    getEventList(): Observable<GetAllEventListResponse> {
        return this.network.getEventList()
    }

    addParticipantsToEvent(eventId: string, participantIds: string[]): Observable<void> {
        return this.network.addParticipantsToEvent(eventId, participantIds)
    }

    addParticipantsToPrefilledEvent(participantIds: string[]): Observable<void> {
        return this.network.addParticipantsToPrefilledEvent(participantIds)
    }
}


export const mapEventModelToTableDisplay = (event: EventModel) => {
    const m = moment.locale('th')
    return [
        '' + event._id,
        `${moment(event.fromDate).format('LL')}  ถึง   ${moment(event.toDate).format('LL')}` ,
        '' + event.name,
        `${event.participantsCount ?? '0'} / ${event.participantLimit ?? 0}`
    ]
}
export const mapEventModelsToTableDisplay = (events: EventModel[]) => {
    const m = moment.locale('th')
    return events.map(event => mapEventModelToTableDisplay(event))
}

export const mapParticipantModelsToTableDisplay = (participants: EXMParticipantEntity[], eventsList?: EventModel[]) => {
    return participants.map(data => mapParticipantModelToTableDisplay(data, eventsList))
}
