import {Scene} from '../../foundation/mvvm/Scene'
import {LoginVM} from './LoginVM'
import {LoginOutput} from './LoginIO'
import {BehaviorSubject, Subject} from 'rxjs'

interface LoginSceneInput {
    username$: BehaviorSubject<string>
    password$: BehaviorSubject<string>
    submit$: Subject<void>
}

export const newLoginScene = (): Scene<LoginSceneInput, LoginOutput, LoginVM> => {
    const vm = new LoginVM()
    const input: LoginSceneInput = {
        username$: new BehaviorSubject<string>(''),
        password$: new BehaviorSubject<string>(''),
        submit$: new Subject()
    }

    return {
        vm,
        input: input,
        output: vm.transform(input)
    }
}
