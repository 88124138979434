import React, {useState} from 'react';
import logo from './logo.svg';
import './App.scss';
import {LoginPage} from './pages/login/LoginPage'
import {RoutesName} from "./utility/RoutesName/RoutesName";
import {Routes, RouteProps, Route} from 'react-router';
import {createTheme, ThemeProvider} from '@mui/material';
import {CreateEventPage} from './pages/createEvent/CreateEventPage';
import {HomePage} from './pages/home/Home';
import {ViewEventPage} from './pages/viewEvents/ViewEvent';
import { SizeVariants } from './foundation/dls/sizes/generated.sizes';
import { ColorNames } from './foundation/dls/theme/generated.colors';
import {ViewParticipantsPage} from "./pages/viewParticipants/ViewParticipants";

interface RouteItem {
    path: string
    component?: RouteProps['element']
    // roles?: string[]
    isExactPath?: boolean
}

const theme = createTheme({
    palette: {
        primary: {
            main:'#3E4CFA',
        },
        secondary: {
            main: 'rgba(245, 163, 47,0.7)',
        },
    },
    typography: {
        fontFamily: [
            'Sukhumvit Set',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    },
    components: {
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    flex: '1 1',
                    paddingRight: '10rem',
                    paddingLeft: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                toolbar: {
                    // backgroundColor: "aliceblue",
                    paddingBlock: '0.45rem'
                },
                selectLabel: {
                    fontSize: '1.5rem'
                }
            }
            },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    flex: '1 1',
                    backgroundColor: 'transparent',

                    boxShadow: 'none'
                },
                rounded: {
                    flex: '1 1',
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    flex: '1 1',
                    backgroundColor: 'rgba(245, 163, 47,0.2)',
                    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05))',
                    borderRadius: '0.5rem',
                    display: 'flex',
                    alignItems: 'center'
                    // boxShadow: 'none'
                },
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    paddingBlock: '0px',
                    paddingInline: '0px',
                }
            }
        },
        MuiSkeleton: {
            styleOverrides : {
                rounded: {
                    flex: '1 1'
                }
            }
        },
        MuiTableHead: {
            styleOverrides : {
                root: {
                    borderBottom: '0.25px solid rgba(163, 167, 211,0.2)'
                }
            }
        },
        MuiTableRow: {},
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    border: 'none',
                    paddingBlock: SizeVariants.$l_spacing,
                    paddingInline: SizeVariants.$l_spacing,
                },
                selected: {
                    backgroundColor: 'black',
                    border: 'none',
                    paddingBlock: SizeVariants.$l_spacing,
                    paddingInline: SizeVariants.$l_spacing,
                    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05))',
                    color: 'black'
                }
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    flex: '1 1 auto',
                    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05))',
                    borderRadius: '15px'
                }
            }
        },
        MuiSelect: {
            styleOverrides : {
                select: {
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    borderBottom: 'none',
                    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05))',

                },
                standard: {
                    // border: 'none',
                    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05))',
                    borderRadius: '10px',
                    paddingBlock: SizeVariants.$m_spacing,
                    paddingInline: SizeVariants.$l_spacing,
                    borderBottom: 'none',

                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: 'none',
                },
                head : {
                    fontWeight: '600',
                    color: ColorNames.text3
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {

                root: {
                    // backgroundColor: ColorNames.white,
                    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05))',
                    fontSize: '8px'

                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    minWidth: '45%'
                }
            }
        }
    }
});

export const APP_VERSION = '1.0.0'

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route path={'*'} element={<LoginPage/>}/>
                <Route path={RoutesName.home} element={<HomePage/>}/>
                <Route path={RoutesName.createEvent} element={<CreateEventPage/>}/>
                <Route path={RoutesName.viewEvent} element={<ViewEventPage/>}/>
                <Route path={RoutesName.viewParticipants} element={<ViewParticipantsPage/>}/>
            </Routes>
        </ThemeProvider>
    )
        ;
}

export default App;
