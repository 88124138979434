import React, { useMemo} from 'react'
import {
    UserNameAndPasswordTextField,
    UsernameAndPasswordTextFieldType
} from '../../components/base/UsernameAndPasswordTextField/UserNameAndPasswordTextField'
import {newLoginScene} from './newLoginScene'
import {Button} from '../../components/base/Button/Button'
import {useT} from '../../foundation/hooks/useT'
import {Form} from '../../containers/Form/Form'
import {Flex} from '../../containers/Flex/Flex'
import {Spacer} from '../../containers/Spacer/Spacer'
import {Size, SizeMultiplier, SizeVariants} from '../../foundation/dls/sizes/generated.sizes'
import {useSubscription} from '../../foundation/hooks/useSubscription'
import {Label} from "../../components/base/Label/Label";
import {FontVariants} from '../../foundation/dls/sizes/generated.fonts';
import {BehaviorSubject, shareReplay, Subject} from "rxjs";
import {ErrorBar} from "../../components/base/ErrorBar/ErrorBar";
import './LoginPage.scss'
import {useNavigate} from "react-router-dom";
import {ColorNames} from "../../foundation/dls/theme/generated.colors";
import {APP_VERSION} from "../../App";
import {Loading} from '../../components/base/Loading/Loading';
import {LoadingCase} from '../../components/base/Loading/LoadingIO';
import {RoutesName} from '../../utility/RoutesName/RoutesName';

export const LoginPage: React.FC<any> = () => {
    const t = useT()
    const {input, output} = newLoginScene()
    const navigate = useNavigate()
    const loginError$ = useMemo(() => new BehaviorSubject<boolean>(false), [])
    const loginErrorText$ = useMemo(() => new BehaviorSubject<string>(''), [])
    const showLoading$ = new Subject<boolean>()

    console.log("ENV_NODE = " + JSON.stringify(process.env))

    useSubscription(output.loggedIn$, (e) => {
        showLoading$.next(false)
        navigate(RoutesName.home)
    })

    useSubscription(output.error$, (e) => {
        showLoading$.next(false)
        loginError$.next(true)
        loginErrorText$.next(e.message)
    })

    return (
        <div className={'login-parent-container'}>
            <Loading
                input={{
                    type: LoadingCase.login,
                    isLoading: showLoading$
                }}/>

            <div className={'body'}>

                <div className={'card'}>

                    <div className={'login'}>
                        <Form output={{submit$: input.submit$}}>
                            <Flex input={{justifyContent: 'flex-end'}}>
                                <img src={'/assets/logo.png'}
                                     className={'app-logo'}/>

                                <Spacer size={SizeVariants.$xxs_spacing}/>

                                <Label input={{
                                    text$: t('login_page_title'),
                                    style: FontVariants.title,
                                    textAlign: 'center'
                                }}/>
                                <Spacer size={SizeVariants.$m_spacing}/>
                                <ErrorBar input={{
                                    text$: loginErrorText$,
                                    error$: loginError$
                                }}/>
                                <Spacer size={SizeVariants.$l_spacing}/>

                                <UserNameAndPasswordTextField
                                    input={{
                                        name: 'username',
                                        type: UsernameAndPasswordTextFieldType.normal,
                                        text$: input.username$.pipe(shareReplay(0)),
                                        label$: 'ชื่อผู้ใช้งาน',
                                        error$: loginError$,
                                        placeholder$: 'กรุณาใส่ชื่อผู้ใช้ของคุณ',
                                    }}
                                    output={{
                                        text$: input.username$
                                    }}
                                />

                                <Spacer size={SizeVariants.$xl_spacing}/>

                                <UserNameAndPasswordTextField
                                    input={{
                                        name: 'password',
                                        type: UsernameAndPasswordTextFieldType.passwordWithForgetButton,
                                        text$: input.password$.pipe(shareReplay(0)),
                                        label$: t('login_page_password_label'),
                                        error$: loginError$,
                                        placeholder$: 'กรุณาใส่รหัสผ่านของคุณ',
                                    }}
                                    output={{
                                        text$: input.password$
                                    }}
                                />

                                <Spacer sizeRem={Size.rem(SizeMultiplier.xs)}/>

                                <Spacer size={SizeVariants.$xl_spacing}/>

                                <Button
                                    input={{
                                        type: 'button',
                                        text$: t('login_page_login_button')
                                    }}
                                    output={{
                                        click$: () => {
                                            showLoading$.next(true)
                                            input.submit$.next()
                                        }
                                    }}
                                />

                                <Spacer sizeRem={Size.rem(SizeMultiplier.m)}/>
                                <Label input={{
                                    text$: 'เวอร์ชั่น : ' + APP_VERSION + ' ภาคตะวันออกเฉียงเหนือตอนบน',
                                    style: FontVariants.subtitleBold,
                                    color: ColorNames.greyDivider,
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}/>
                                <Spacer sizeRem={Size.rem(SizeMultiplier.s)}/>

                            </Flex>

                        </Form>
                    </div>

                </div>
            </div>
        </div>
    )
}
