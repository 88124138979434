import {combineLatestWith, map, mergeMap, of, retry, shareReplay, startWith} from 'rxjs'
import {LoginUseCase} from '../../models/usecases/LoginUseCase'
import {VM} from '../../foundation/mvvm/VM'
import {LoginInput, LoginOutput} from './LoginIO'
import {withLatestFrom} from 'rxjs/operators'
import {ErrorTracker, trackError} from '../../utility/rx/ErrorTracker'

export class LoginVM implements VM<LoginInput, LoginOutput> {
    constructor(private useCase: LoginUseCase = new LoginUseCase()){
    }

    transform(input: LoginInput): LoginOutput {
        const userPass = input.username$.pipe(combineLatestWith(input.password$))
        const loginError = new ErrorTracker()

        return {
            loggedIn$: input.submit$.pipe(
                withLatestFrom(userPass),
                mergeMap(([_, [username, password]]) => {
                    return this.useCase.login(username, password)
                }),
                trackError(loginError),
                retry()
            ),
            error$: loginError
        }
    }
}
