import {Observable, shareReplay} from 'rxjs'
import { ObservableOrValue } from '../rx/ObservableOrValue'
import { Options, useObservable } from './useObservable'
import { skip } from 'rxjs/operators'

interface Modifiers<T> {
  whenObs?: (obs: Observable<T>) => Observable<T>
  whenVal?: (val: T) => T
}

export function useObservableOrValue<T>(
  obs?: ObservableOrValue<T>,
  modifiers?: Modifiers<T>,
  options?: Options<T>,
): T | undefined {
  if (obs == null) return
  if (obs instanceof Observable) {
    const fallback = (obs: Observable<T>) => obs
    const whenObs = modifiers?.whenObs ?? fallback
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useObservable(whenObs(obs.pipe(shareReplay(0), skip(1))))
  } else {
    const fallback = (val: T) => val
    const whenVal = modifiers?.whenVal ?? fallback
    return whenVal(obs)
  }
}
