import {ColorNames} from "../theme/generated.colors";
import {FontVariants} from "../sizes/generated.fonts";

interface Spec {
    className?: string
    color?: ColorNames
}

export function getSpec(style: FontVariants): Spec {
    // This function will return default styles of each Variants
    switch (style) {
        case FontVariants.title:
            return {
                className: 'label-title',
                color: ColorNames.text,
            }
        case FontVariants.heading:
            return {
                className: 'label-heading-bold',
                color: ColorNames.text,
            }
        case FontVariants.subtitle:
            return {
                className: 'label-subtitle',
                color: ColorNames.text,
            }
        case FontVariants.subtitleBold:
            return {
                className: 'label-subtitle-bold',
                color: ColorNames.text,
            }
        case FontVariants.subtitleBoldUnderline:
            return {
                className: 'label-subtitle-bold-underline',
                color: ColorNames.text,
            }
        case FontVariants.paragraph:
            return {
                className: 'label-paragraph',
                color: ColorNames.text,
            }
        case FontVariants.paragraphBold:
            return {
                className: 'label-paragraph-bold',
                color: ColorNames.text,
            }
        case FontVariants.small:
            return {
                className: 'label-small',
                color: ColorNames.text,
            }
        case FontVariants.smallBold:
            return {
                className: 'label-small-bold',
                color: ColorNames.text,
            }
        default:
            return {
                className: 'label-paragraph',
                color: ColorNames.text,
            }
    }
}

export function getLinkSpec(style: FontVariants): Spec {
    // This function will return default styles of each Variants
    switch (style) {
        case FontVariants.title:
            return {
                className: 'link-title',
                color: ColorNames.text,
            }
        case FontVariants.heading:
            return {
                className: 'link-heading',
                color: ColorNames.text,
            }
        case FontVariants.subtitle:
            return {
                className: 'link-subtitle',
                color: ColorNames.text,
            }
        case FontVariants.subtitleBold:
            return {
                className: 'link-subtitle-bold',
                color: ColorNames.text,
            }
        case FontVariants.subtitleBoldUnderline:
            return {
                className: 'link-subtitle-bold-underline',
                color: ColorNames.text,
            }
        case FontVariants.paragraph:
            return {
                className: 'link-paragraph',
                color: ColorNames.text,
            }
        case FontVariants.paragraphBold:
            return {
                className: 'link-paragraph-bold',
                color: ColorNames.text,
            }
        case FontVariants.small:
            return {
                className: 'link-small',
                color: ColorNames.text,
            }
        case FontVariants.smallBold:
            return {
                className: 'link-small-bold',
                color: ColorNames.text,
            }
        case FontVariants.linkThinAndItalicAndUnderline:
            return {
                className: 'link-thin-italic-underline',
                color: ColorNames.text,
            }
        default:
            return {
                className: 'link-thin-italic-underline',
                color: ColorNames.text,
            }
    }
}
