import {
    Accordion, AccordionDetails, AccordionSummary,
    StepConnector,
    stepConnectorClasses,
    StepIconProps,
    styled,
} from "@mui/material";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import {Label} from "../../components/base/Label/Label";
import {FontVariants} from "../../foundation/dls/sizes/generated.fonts";
import {SizeVariants} from "../../foundation/dls/sizes/generated.sizes";
import {Spacer} from "../../containers/Spacer/Spacer";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {AreaCondition} from "../../models/network/EventNetwork";
import MapRoundedIcon from '@mui/icons-material/MapRounded';

export const ColorlibConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(39, 174, 96) 0%,rgb(62, 76, 250) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(39, 174, 96) 0%,rgb(46, 224, 121) 50%,rgb(39, 174, 96) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 4.5,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

export const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({theme, ownerState}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 43,
    height: 43,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: '#3E4CFA',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#27AE60',
    }),
}));

export function ColorlibStepIcon(props: StepIconProps) {
    const {active, completed, className} = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <CalendarMonthRoundedIcon fontSize={'small'}/>,
        2: <PersonAddRoundedIcon fontSize={'small'}/>,
        3: <CheckRoundedIcon fontSize={'small'}/>
    };

    return (
        <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

// ------------------------------------------------------------------------------------
// const renderCreateEventBody = () => {
//     return <div>
//         {renderBasicInfo()}
//
//         <Spacer size={SizeVariants.$l_spacing}/>
//
//         {renderAreaSection()}
//     </div>
// }
//
// const renderStatus = () => {
//     return <div className={'status-container'}>
//         <Label input={{
//             text$: 'สถานะปัจจุบัน',
//             style: FontVariants.subtitleBold
//         }}/>
//         <Spacer size={SizeVariants.$s_spacing}/>
//
//         <ToggleButtonGroup
//             color="primary"
//             value={eventStatus}
//             exclusive
//             onChange={handleEventStaus}
//             aria-label="Platform"
//         >
//             <ToggleButton value="draft">
//                 <DesignServicesRoundedIcon/>
//                 <Spacer size={SizeVariants.$s_spacing}/>
//                 ร่าง
//                 <Spacer size={SizeVariants.$xxs_spacing}/>
//             </ToggleButton>
//             <ToggleButton value="publish">
//                 <PublishedWithChangesRoundedIcon/>
//                 <Spacer size={SizeVariants.$s_spacing}/>
//                 เผยแพร่
//             </ToggleButton>
//             <ToggleButton value="completed">
//                 <AssignmentTurnedInRoundedIcon/>
//                 <Spacer size={SizeVariants.$s_spacing}/>
//                 เสร็จ
//             </ToggleButton>
//         </ToggleButtonGroup>
//     </div>
// }
//
// const renderBasicInfo = () => {
//     return <div className={'card'}>
//         <div className={'title-container'}>
//             <CalendarMonthRoundedIcon/>
//             <Spacer size={SizeVariants.$l_spacing}/>
//             <Label input={{
//                 text$: 'ข้อมูลกิจกรรม',
//                 style: FontVariants.heading,
//                 textAlign: 'center'
//             }}/>
//         </div>
//
//         <Spacer size={SizeVariants.$l_spacing}/>
//         <div className={'row'}>
//             <TextField
//                 input={{
//                     name: 'name',
//                     type: TextFieldType.normal,
//                     isMandatory: false,
//                     // text$: input.firstName$.pipe(shareReplay(0)),
//                     label$: 'ชื่อกิจกรรม *',
//                     placeholder$: 'ระบุชื่อกิจกรรม'
//                 }}
//                 output={{
//                     // text$: input.firstName$
//                 }}
//             />
//             <Spacer size={SizeVariants.$l_spacing}/>
//             <DatePicker input={{
//                 title: 'วันที่กิจกรรม'
//             }}
//                         output={{}}/>
//
//         </div>
//         <Spacer size={SizeVariants.$xl_spacing}/>
//
//         <div className={'row'}>
//             {renderStatus()}
//             <Spacer size={SizeVariants.$l_spacing}/>
//
//             <Slider input={{title: 'จำนวนผุ้เข้าร่วม', prefillValue: 100, min: 10, max: 2000}} output={{}}></Slider>
//             <Spacer size={SizeVariants.$l_spacing}/>
//             <Slider input={{title: 'อายุของผู้เข้าร่วม', prefillValue: [18, 80], min: 1, max: 120, isRange: true}}
//                     output={{}}/>
//
//         </div>
//     </div>
// }
//
// const renderAreaSection = () => {
//
//     return <div className={'card'}>
//         <div className={'title-container'}>
//             <AddLocationAltIcon/>
//             <Spacer size={SizeVariants.$l_spacing}/>
//             <Label input={{
//                 text$: 'เพิ่มกฏให้กิจกรรม',
//                 style: FontVariants.heading,
//                 textAlign: 'center'
//             }}/>
//         </div>
//         <Spacer size={SizeVariants.$m_spacing}/>
//         <AddressSelector
//             output={{}} input={{mode: 'add'}}/>
//         <Spacer size={SizeVariants.$l_spacing}/>
//         <Label input={{
//             text$: 'เพิ่มกฏให้กิจกรรม',
//             style: FontVariants.subtitleBold,
//             // textAlign: 'center'
//         }}/>
//         <Spacer size={SizeVariants.$s_spacing}/>
//         <TableView input={{
//             header: ['จังหวัด', 'เขต/อำเภอ', 'แขวง/ตำบล', 'หมู่่บ้าน'],
//             content: [
//                 ['sdjk', '-', '-', '-'],
//                 ['sdjk', '-', '-', '-'],
//                 ['sdjk', '-', '-', '-'],
//             ],
//             hasDeleteOption: true
//         }} output={{}}/>
//     </div>
// }

export const SummaryRowItem = (icon: string, title: string, content: string) => {
    return <div className={'summary-row'}>
        <div className={'heading'}>
            {/*{Icons[icon]}*/}
            {/*<Icon itemID={icon}/>*/}
            <Spacer size={SizeVariants.$m_spacing}/>
            <Label input={{
                text$: title,
                style: FontVariants.subtitleBold,
                textAlign: 'center'
            }}/>
        </div>

        <Spacer size={SizeVariants.$m_spacing}/>

        <Label input={{
            text$: content,
            style: FontVariants.subtitle,
            textAlign: 'center'
        }}/>
    </div>
}

export const renderSummaryDivider = () => {
    return [<Spacer size={SizeVariants.$l_spacing}/>,
        <div className={'divider'}/>,
        <Spacer size={SizeVariants.$l_spacing}/>
    ]
}
const extractAreaCondition = (area: AreaCondition[] | undefined) => {
    if (area) {
        return `${area.map(val => extractAreaCon(val))}`
    }
    return ''
}

const renderAreaCondition = (areas: AreaCondition[] | undefined) => {

    if (areas !== undefined && areas.length !== 0) {
        return <div className={'area-condition'}>
            {areas?.map((area, index) => <Label input={{
                text$: `-  ${extractAreaCon(area)}`,
                style: FontVariants.subtitle,
                textAlign: 'center'
            }}/>)}
        </div>
    } else {
        return <div className={'area-condition'}>
            <Label input={{
                text$: `ไม่มีเงื่อนไขสำหรับพื้นที่`,
                style: FontVariants.subtitle,
                textAlign: 'center'
            }}/>
        </div>
    }
}
const extractAreaCon = (area: AreaCondition | undefined): string => {
    if (area) {
        return `${area.nameTh}  ${extractAreaCondition(area.subAreas)}`
    }
    return ''
}
export const mapEntryAreaToSummary = (areas: AreaCondition[] | undefined) => {

    return <div className={'summary-row'}>
        <Accordion square={false} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <MapRoundedIcon/>
                <Spacer size={SizeVariants.$m_spacing}/>
                <Label input={{
                    text$: 'พื้นที่',
                    style: FontVariants.subtitleBold,
                    textAlign: 'center'
                }}/>
            </AccordionSummary>
            <AccordionDetails>
                {renderAreaCondition(areas)}
            </AccordionDetails>
        </Accordion>
    </div>
}