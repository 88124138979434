import {useNavigate} from "react-router";
import {Label} from "../../components/base/Label/Label";
import {Toolbar} from "../../components/base/Toolbar/Toolbar";
import {Spacer} from "../../containers/Spacer/Spacer";
import {FontVariants} from "../../foundation/dls/sizes/generated.fonts";
import {SizeVariants} from "../../foundation/dls/sizes/generated.sizes";
import {RoutesName} from "../../utility/RoutesName/RoutesName";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';

import './Home.scss'
import { useEffect } from "react";
import { isUserLoggedIn } from "../../models/network/LoginNetwork";

export const HomePage: React.FC<any> = () => {
    const navigate = useNavigate()

    useEffect(() => {
        if(!isUserLoggedIn()){
            navigate(RoutesName.loginPage)
        }
    })

    return (
        <div className={'home-parent-container'}>
            <Toolbar input={{title: ""}}/>
            <div className={'home-body'}>
                <Spacer size={SizeVariants.$xxl_spacing}/>

                <Label input={{
                    text$: 'ยินดีต้อนรับ, Event Manager',
                    style: FontVariants.title,
                }}/>
                <Spacer size={SizeVariants.$xxl_spacing}/>
                <Label input={{
                    text$: 'จัดการกิจกรรม',
                    style: FontVariants.heading,
                }}/>
                <Spacer size={SizeVariants.$l_spacing}/>
                <div className={'box-container'}>

                    <div className={'box'}
                         onClick={() => navigate(RoutesName.createEvent)
                         }
                    >
                        <div className={'icon-holder'}>
                        <CalendarMonthOutlinedIcon fontSize={'large'} color={'secondary'}/>
                        </div>
                        <Spacer size={SizeVariants.$xxl_spacing}/>
                        <div className={'label-conatiner'}>
                            <Label input={{
                                text$: 'เพิ่มคนเข้ากิจกรรม',
                                style: FontVariants.heading
                            }}/>
                            <Spacer size={SizeVariants.$xs_spacing}/>
                            <Label input={{
                                text$: 'สำหรับการเพิ่มผู้เข้าร่วมกิจกรรมที่สร้างขึ้น',
                                style: FontVariants.subtitle
                            }}/>
                        </div>
                    </div>
                    <Spacer size={SizeVariants.$xxl_spacing}/>

                    <div className={'box'}
                         onClick={() => navigate(RoutesName.viewEvent)
                         }
                    >
                        <div className={'icon-holder'}>
                            <ContactPageRoundedIcon fontSize={'large'} color={'secondary'}/>
                        </div>

                        <Spacer size={SizeVariants.$xxl_spacing}/>
                        <div className={'label-conatiner'}>
                            <Label input={{
                                text$: 'ข้อมูลกิจกรรม',
                                style: FontVariants.heading
                            }}/>
                            <Spacer size={SizeVariants.$xxs_spacing}/>
                            <Label input={{
                                text$: 'เพื่อวัตถุประสงค์ในการส่งออกข้อมูลเป็นรายงาน',
                                style: FontVariants.subtitle
                            }}/>
                        </div>

                    </div>
                </div>
                <Spacer size={SizeVariants.$xxl_spacing}/>
                <Spacer size={SizeVariants.$xxl_spacing}/>
                <Label input={{
                    text$: 'จัดการผู้เข้าร่วม',
                    style: FontVariants.heading,
                }}/>
                <Spacer size={SizeVariants.$l_spacing}/>
                <div className={'box-container'}>

                    <div className={'box'}
                         onClick={() => navigate(RoutesName.viewParticipants)
                         }
                    >
                        <div className={'icon-holder'}>
                            <Groups2RoundedIcon fontSize={'large'} color={'secondary'}/>
                        </div>
                        <Spacer size={SizeVariants.$xxl_spacing}/>
                        <div className={'label-conatiner'}>
                            <Label input={{
                                text$: 'รายชื่อผู้เข้าร่วมกิจกรรม',
                                style: FontVariants.heading
                            }}/>
                            <Spacer size={SizeVariants.$xs_spacing}/>
                            <Label input={{
                                text$: 'สำหรับดูรายชื่อทั้งหมดของผู้เข้าร่วมกิจกรรม',
                                style: FontVariants.subtitle
                            }}/>
                        </div>
                    </div>
                    <Spacer size={SizeVariants.$xxl_spacing}/>
                    <div className={'placeholder-box'}/>
                </div>
            </div>
        </div>
    )

}

