import React, {useState} from 'react'
import {Label} from '../Label/Label'
import {filter} from 'rxjs/operators'
import {useObservableOrValue} from '../../../foundation/hooks/useObservableOrValue'
import {UsernameAndPasswordTextFieldProps} from './UsernameAndPasswordTextFieldIO'
import './UserNameAndPasswordTextField.scss'
import {FontVariants} from "../../../foundation/dls/sizes/generated.fonts";
import {useT} from "../../../foundation/hooks/useT";
import {ColorNames} from "../../../foundation/dls/theme/generated.colors";

export enum UsernameAndPasswordTextFieldType {
    normal,
    password,
    passwordWithForgetButton
}

export const UserNameAndPasswordTextField: React.FC<UsernameAndPasswordTextFieldProps> = ({
                                                        input,
                                                        output,
                                                        ...rest
                                                    }) => {
    const text = useObservableOrValue(input.text$) ?? ''
    const isError = useObservableOrValue(input.error$)
    const errorInlineText = useObservableOrValue(input.errorInLineText$)
    const t = useT()
    const placeholder = useObservableOrValue(input.placeholder$, {
        whenObs: o => o.pipe(filter(o => o !== ''))
    }) ?? ''

    const [showPassword, setShowPassword] = useState(false)
    const eyeClass = "eye-icon"

    const textFieldLabelComponent = () => {
        if (input.label$ == null) return null
        switch (input.type) {
            case UsernameAndPasswordTextFieldType.passwordWithForgetButton: {
                return <div className={'label-container'}>
                    <Label className={'label'}
                           input={{text$: input.label$}}/>
                </div>
            }
            default: {
                return <div className={'label-container'}>
                    <Label className={'label'}
                           input={{text$: input.label$}}/>
                </div>
            }

        }
    }
    const textFieldComponent = () => {
        switch (input.type) {
            case UsernameAndPasswordTextFieldType.passwordWithForgetButton:
            case UsernameAndPasswordTextFieldType.password: {
                return (
                    <div className={isError ? 'text-field-password-error' : 'text-field-password'}>
                        <input
                            className={'input-password'}
                            name={input.name}
                            type={showPassword ? 'text' : 'password'}
                            value={text}
                            required
                            placeholder={placeholder}
                            onChange={event => output?.text$?.next(event.target.value)}
                        />
                    </div>
                )
            }
            default: {
                return (
                    <div className={isError ? 'text-field-error' : 'text-field'}>
                        <input name={input.name}
                               type={'text'}
                               value={text}
                               required
                               placeholder={placeholder}
                               onChange={event => output?.text$?.next(event.target.value)}
                        />
                    </div>
                )
            }
        }
    }

    const errorInlineComponent = () => {
        if (errorInlineText == null || errorInlineText == '') return null
        return (<div className={'text-error-inline'}>
            <img className={'red-warning-img'}
                 src={'/assets/icon/RedWarning.png'}
                 alt="logo"/>
            <Label className={'warning-label'}
                   input={{
                       text$: input.errorInLineText$ ?? '',
                       color: ColorNames.danger,
                       fontSize: '16px'
                   }}/>
        </div>)
    }

    return (
        <div className={'username-password-textfield-container'} {...rest}>
            {textFieldLabelComponent()}
            {textFieldComponent()}
            {errorInlineComponent()}
        </div>
    )
}
