export enum Colors {
    primary  = '#3E4CFA',
    primary2 = '#1F6FDE',
    primary3 = '#2FA6F5',
    primary4 = '#1FC4DE',
    primary5 = '#23FADD',
    extended1  = '#F54847',
    extended2  = '#F5A32F',
    extended3  = '#F5C922',
    success1 = '#27AE60',
    text = '#001D45',
    text2 = '#000976',
    text3 = '#2333FA',
    text4 = '#A3A7D3',
    text5 = '#FFFFFF',
    primary1Background = '#3E4CFA',
    primary1TonedUp = '#6F8FFE',
    background  = '#F5F6FF',
    overlayBackground  = 'rgba(100, 100, 100,0.5)',
    tableCellAlternate  = 'rgba(245, 163, 47,0.05)',
    tableCellSelected  = 'rgba(245, 163, 47,0.2)',
    tableCellPlaceholder  = 'rgba(163, 167, 211,0.2)',
    shadow  = '#001D45',
    componentBackground  = '#FFFFFF',
    disabledBackground  = '#EBEBEF',
    border  = '#E4E4E4',
    placeholderText  = '#A3A7D3',
    white = '#FFFFFF',
    black = '#000000',
    cardBg = '#fafafa',
    neutral = '#f0f0f0',
    greyDivider = '#BFBFBF',
    redErrorBackground = '#CF1322',
    errorRed = '#F5222D',
    green = '#52C41A',
    darkGreen = '#2D7738',
    darkRed = '#772D2D',
    danger = '#FF4D4F',
    darkGrey = '#424141',
    textContrast = '#fff',
    disabled = 'rgba(0, 0, 0, 0.25)',
}

export enum ColorNames {
    primary  = 'primary ',
    primary2 = 'primary2',
    primary3 = 'primary3',
    primary4 = 'primary4',
    primary5 = 'primary5',
    extended1  = 'extended1 ',
    extended2  = 'extended2 ',
    extended3  = 'extended3 ',
    success1 = 'success1',
    text = 'text',
    text2 = 'text2',
    text3 = 'text3',
    text4 = 'text4',
    text5 = 'text5',
    primary1Background = 'primary1Background',
    primary1TonedUp = 'primary1TonedUp',
    background  = 'background ',
    overlayBackground  = 'overlayBackground ',
    tableCellAlternate  = 'tableCellAlternate ',
    tableCellSelected  = 'tableCellSelected ',
    tableCellPlaceholder  = 'tableCellPlaceholder ',
    shadow  = 'shadow ',
    componentBackground  = 'componentBackground ',
    disabledBackground  = 'disabledBackground ',
    border  = 'border ',
    placeholderText  = 'placeholderText ',
    white = 'white',
    black = 'black',
    cardBg = 'cardBg',
    neutral = 'neutral',
    greyDivider = 'greyDivider',
    redErrorBackground = 'redErrorBackground',
    errorRed = 'errorRed',
    green = 'green',
    darkGreen = 'darkGreen',
    darkRed = 'darkRed',
    danger = 'danger',
    darkGrey = 'darkGrey',
    textContrast = 'textContrast',
    disabled = 'disabled',
}
