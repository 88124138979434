import {LoginNetwork} from "../network/LoginNetwork";
import {Observable} from "rxjs";
import {EventModel, EventNetwork, GetAllEventListResponse} from "../network/EventNetwork";
import {AxiosResponse} from "axios";
import {
    EXMParticipantEntity, EXMParticipantGender,
    GetParticipantListWithEventIDResponse,
    ParticipantNetwork
} from "../network/ParticipantNetwork";
import moment from "moment";
import {compact} from "lodash";

export class ParticipantUseCase {
    constructor(private network: ParticipantNetwork = new ParticipantNetwork()) {
    }

    getParticipantListWithEventID(eventRefId: string): Observable<GetParticipantListWithEventIDResponse> {
        return this.network.getParticipantListWithEventID(eventRefId)
    }

    getAllParticipantList(searchText1?: string, searchText2?: string, searchText3?: string, searchText4?: string, searchText5?: string, page?: number): Observable<GetParticipantListWithEventIDResponse> {
        return this.network.getAllParticipantList(searchText1, searchText2, searchText3, searchText4, searchText5, page)
    }
}

export const mapParticipantModelToTableDisplay = (participant: EXMParticipantEntity, eventsList?: EventModel[]): string[] => {
    let eventDate = '-'
    if (participant.checkins.length !== 0) {
        eventDate = `${moment(participant.checkins[participant.checkins.length - 1].date).format('LL')}`
    }

    let eventName = 'เก็บข้อมูล'
    if (eventsList) {
        eventName = `${eventsList.find((val => val._id == participant.eventId))?.name ?? 'เก็บข้อมูล'}`
    }
    return [
        participant._id ?? '',
        '' + eventDate,
        '' + eventName,
        '' + participant.citizenCardInfo.idNumber,
        '' + participant.generatedId,
        '' + formatName(participant),
        '' + getGenderText(participant.personalInfo.gender),
        `${moment(participant.personalInfo.dateOfBirth).format('LL')}`,
        generateAddress(participant),
        `${moment(participant.citizenCardInfo.expiryDate).format('LL')}`
    ]
}

export const getGenderText = (gender: EXMParticipantGender) => {
    // const { t } = useTranslation()
    switch (gender) {
        case EXMParticipantGender.Male:
            return 'ชาย'
        case EXMParticipantGender.Female:
            return 'หญิง'
        case EXMParticipantGender.Unspecified:
            return 'ไม่ระบุ'
    }
}

export function generateAddress(participantModel: EXMParticipantEntity): string {
    const {
        houseNumber,
        villageName,
        subDistrictName,
        districtName,
        provinceName,
        road,
        street
    } = participantModel.address

    return (
        houseNumber +
        ' ' +
        villageName +
        ' ' +
        street +
        ' ' +
        road +
        ' ' +
        subDistrictName +
        ' ' +
        districtName +
        ' ' +
        provinceName
    )
}

export function formatName(participantModel: EXMParticipantEntity): string {
    const components = compact([participantModel.personalInfo.name.th?.title, participantModel.personalInfo.name.th?.firstname, participantModel.personalInfo.name.th?.middlename, participantModel.personalInfo.name.th?.lastname] as (
        | string
        | undefined
        )[])
    return components.join(' ')
}
