import {VM} from "../../foundation/mvvm/VM";
import {LoginUseCase} from "../../models/usecases/LoginUseCase";
import {CreateEventInput, CreateEventOutput} from "./CreateEventIO";
import {map, Observable, of, retry, shareReplay, Subject} from "rxjs";
import {delay, filter, tap} from "rxjs/operators";
import {
    EventUseCase,
    mapEventModelsToTableDisplay,
    mapEventModelToTableDisplay, mapParticipantModelsToTableDisplay
} from "../../models/usecases/EventUseCase";
import {ErrorTracker, trackError} from "../../utility/rx/ErrorTracker";
import {mapParticipantModelToTableDisplay, ParticipantUseCase} from "../../models/usecases/ParticipantUseCase";
import {EntryCondition, EventModel, GetAllEventListResponse} from "../../models/network/EventNetwork";
import {EXMParticipantEntity} from "../../models/network/ParticipantNetwork";

export class CreateEventVM implements VM<CreateEventInput, CreateEventOutput> {
    constructor(private eventUseCase: EventUseCase = new EventUseCase(),
                private participantUseCase: ParticipantUseCase = new ParticipantUseCase()) {
    }

    transform(input: CreateEventInput): CreateEventOutput {
        const eventError$ = new ErrorTracker()
        const eventsSubject = new Subject<string[][]>()
        const participantListSubject = new Subject<string[][]>()
        const selectedParticipantListSubject = new Subject<string[][]>()
        const navigateToFinishScreen = new Subject<void>()
        const navigateToParticipant = new Subject<{ navigate: boolean, available: number }>()
        const eventCondition$ = new Subject<EntryCondition | undefined>()
        let currentEventRefID = ''
        let allEvents: EventModel[] = []
        let allParticipant: EXMParticipantEntity[] = []

        function refresh(useCase: EventUseCase) {
            useCase.getEventList().pipe(
                map(item => {
                    return item
                }),
                trackError(eventError$),
                retry(0),
                shareReplay(0),
            ).subscribe((item) => {
                const stringList = item.events.map(data => {
                    return mapEventModelToTableDisplay(data)
                })
                allEvents = item.events
                eventsSubject.next(stringList)
            })
        }

        //on load at first time
        setTimeout(() => {
            refresh(this.eventUseCase)
        }, 10)

        input.selectEvent.pipe(filter(item => item !== '')).subscribe((eventRefID) => {
            const eventInfo = allEvents.find(event => event._id == eventRefID)
            if ((eventInfo?.participantsCount ?? 0) >= (eventInfo?.participantLimit ?? 0)) {
                // Limit Over Don't allow and show error
                navigateToParticipant.next({navigate: false, available: 0})
            } else {
                this.participantUseCase.getParticipantListWithEventID(eventRefID)
                    .subscribe((response) => {
                        const displayModel = response.participantList.map(data => {
                            return mapParticipantModelToTableDisplay(data)
                        }) ?? []
                        allParticipant = response.participantList
                        currentEventRefID = eventRefID
                        navigateToParticipant.next({
                            navigate: true,
                            available: (eventInfo?.participantLimit ?? 0) - (eventInfo?.participantsCount ?? 0)
                        })
                        eventCondition$.next(eventInfo?.entryCondition)
                        input.searchParticipant$.next(input.searchParticipant$.value)
                    })
            }
        })

        input.selectParticipants.subscribe((item) => {
            navigateToFinishScreen.next()
            selectedParticipantListSubject.next(mapParticipantModelsToTableDisplay(allParticipant.filter(value => item.includes(value._id ?? ''))))
            this.eventUseCase.addParticipantsToEvent(currentEventRefID, item).pipe(
                trackError(eventError$)
            )
                .subscribe((response) => {
                    // navigateToFinishScreen.next()
                    // selectedParticipantListSubject.next(mapParticipantModelsToTableDisplay(allParticipant.filter(value => item.includes(value._id ?? ''))))
                }, (e) => {
                    navigateToParticipant.next({navigate: false, available: 0})
                })
        })

        input.searchEvent$.subscribe((searchText) => {
            if (searchText === '') {
                eventsSubject.next(mapEventModelsToTableDisplay(allEvents))
            } else {
                eventsSubject.next(mapEventModelsToTableDisplay(allEvents.filter(event =>
                    event.name.toLowerCase().includes(searchText.toLowerCase())))
                )
            }
        })

        input.searchParticipant$.subscribe((searchText) => {
            if (searchText === '') {
                participantListSubject.next(mapParticipantModelsToTableDisplay(allParticipant))
            } else {
                participantListSubject.next(mapParticipantModelsToTableDisplay(allParticipant.filter(data => {
                            return data.eventId?.toLowerCase().includes(searchText.toLowerCase())
                                || data.personalInfo.name.th.firstname.includes(searchText.toLowerCase())
                                || data.personalInfo.name.th.middlename.includes(searchText.toLowerCase())
                                || data.personalInfo.name.th.lastname.includes(searchText.toLowerCase())
                                || data.personalInfo.name.th.title.includes(searchText.toLowerCase())
                                || data.personalInfo.name.th.nickname?.includes(searchText.toLowerCase())
                                || data.personalInfo.name.th.firstname.includes(searchText.toLowerCase())
                                || data.address.provinceName?.includes(searchText.toLowerCase())
                                || data.address.districtName?.includes(searchText.toLowerCase())
                                || data.address.subDistrictName?.includes(searchText.toLowerCase())
                                || data.address.villageName?.includes(searchText.toLowerCase())
                                || data.contactNo?.includes(searchText.toLowerCase())
                        })
                    )
                )
            }
        })

        return {
            events: eventsSubject.asObservable(),
            participants: participantListSubject.asObservable(),
            selectedParticipants: selectedParticipantListSubject.asObservable(),
            navigateToFinishScreen: navigateToFinishScreen.asObservable(),
            eventCondition$: eventCondition$.asObservable(),
            navigateToParticipant$: navigateToParticipant.asObservable(),
        }
    }
}
