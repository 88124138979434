
export enum FontVariants {
    title,
    heading,
    subtitle,
    subtitleBold,
    subtitleBoldUnderline,
    paragraph,
    paragraphBold,
    linkThinAndItalicAndUnderline,
    smallBold,
    small,
}
