import React from "react";
import {confirmAlert} from "react-confirm-alert";
import {Spacer} from "../../containers/Spacer/Spacer";
import {SizeVariants} from "../../foundation/dls/sizes/generated.sizes";
import {Label} from "../../components/base/Label/Label";
import {FontVariants} from "../../foundation/dls/sizes/generated.fonts";
import {ColorNames} from "../../foundation/dls/theme/generated.colors";
import './ErrorNotification.scss'

export function notificationError(header?: string, description?: string) {
    const options = {
        childrenElement: () => <div/>,
        customUI: ({onClose}: { onClose: any }) => {
            return <div
                className={'notification-float1 failure-container'}>
                <img src={'/assets/icon/RedWarning.png'}
                     className={'notification-icon'}
                />
                <Spacer size={SizeVariants.$m_spacing}/>
                <div className={'notification-label-container'}>
                    <Label input={{
                        text$: header ?? "เกิดข้อผิดพลาด",
                        style: FontVariants.smallBold,
                        flex: "1",
                        onlyOneLine: true,
                        color: ColorNames.darkRed
                    }}/>
                    <Label input={{
                        text$: description ?? "กรุณาลองใหม่อีกครั้ง",
                        style: FontVariants.small,
                        flex: "1",
                        color: ColorNames.darkRed,
                        onlyOneLine: true,
                    }}/>
                </div>
                <Spacer size={SizeVariants.$m_spacing}/>
                <img src={'/assets/icon/x-icon.png'}
                     className={'notification-x-icon'}
                     onClick={(event => {
                         onClose()
                     })}/>
            </div>
        },
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {
        },
        afterClose: () => {
        },
        onClickOutside: () => {
        },
        onKeypressEscape: () => {
        },
        overlayClassName: 'error-transparent-background'
    }
    confirmAlert(options)
}

export function notificationSuccess(header?: string, description?: string) {
    const options = {
        childrenElement: () => <div/>,
        customUI: ({onClose}: { onClose: any }) => {
            return <div
                className={'notification-float1 success-container'}>
                <img src={'/assets/icon/NotificationSuccess.png'}
                     className={'notification-icon'}
                />
                <Spacer size={SizeVariants.$m_spacing}/>
                <div className={'notification-label-container'}>
                    <Label input={{
                        text$: header ?? "ทำรายการสำเร็จ",
                        style: FontVariants.smallBold,
                        flex: "1",
                        onlyOneLine: true,
                        color: ColorNames.green
                    }}/>
                    <Label input={{
                        text$: description ?? "",
                        style: FontVariants.small,
                        flex: "1",
                        color: ColorNames.green,
                        onlyOneLine: true,
                    }}/>
                </div>
                <Spacer size={SizeVariants.$m_spacing}/>
                <img src={'/assets/icon/x-icon.png'}
                     className={'notification-x-icon'}
                     onClick={(event => {
                         onClose()
                     })}/>
            </div>
        },
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {
        },
        afterClose: () => {
        },
        onClickOutside: () => {
        },
        onKeypressEscape: () => {
        },
        overlayClassName: 'error-transparent-background'
    }
    confirmAlert(options)
}
