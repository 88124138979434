import {LoginNetwork, LoginResponse} from '../network/LoginNetwork'
import {Observable, tap} from "rxjs";
import {AxiosResponse} from "axios";

export class LoginUseCase {
    constructor(private network: LoginNetwork = new LoginNetwork()) {
    }

    login(username: string, password: string): Observable<any> {
        return this.network.login(username, password).pipe()
    }
}
