import {defer, from, Observable} from "rxjs";
import {SIT_URL} from "../../utility/Axios/AxiosHandler";
import {encryptStorage, StorageKey} from "../../utility/storage";
import {DBModel} from "./EventNetwork";

export class ParticipantNetwork {
    getParticipantListWithEventID(eventRefId: string): Observable<GetParticipantListWithEventIDResponse> {
        const accessToken = encryptStorage.getItem<string>(StorageKey.accessToken)
        const username = encryptStorage.getItem<string>(StorageKey.username)
        const body = {
            authUsername: username ?? '',
            authentication: accessToken ?? '',
            eventId: eventRefId
        }
        const req = new Request(SIT_URL + "/participantList", {
            method: 'POST',
            body: JSON.stringify(body)
        });
        return defer(() => from(fetch(req).then(response => response.json()).then(response => {
                // console.log('------Response from fetch = ' + JSON.stringify(response))
                return response
            })
        ))
    }

    getAllParticipantList(searchText1?: string, searchText2?: string, searchText3?: string, searchText4?: string, searchText5?: string, page?: number): Observable<GetParticipantListWithEventIDResponse> {
        const accessToken = encryptStorage.getItem<string>(StorageKey.accessToken)
        const username = encryptStorage.getItem<string>(StorageKey.username)
        const body = {
            authUsername: username ?? '',
            authentication: accessToken ?? '',
            searchText1: searchText1,
            searchText2: searchText2,
            searchText3: searchText3,
            searchText4: searchText4,
            searchText5: searchText5,
            page: page
        }
        const req = new Request(SIT_URL + "/allParticipantList", {
            method: 'POST',
            body: JSON.stringify(body)
        });
        return defer(() => from(fetch(req).then(response => response.json()).then(response => {
                // console.log('------Response from fetch = ' + JSON.stringify(response))
                return response
            })
        ))
    }

}

export interface GetParticipantListWithEventIDResponse {
    participantList: EXMParticipantEntity[]
    pageSize: number
}

export interface EXMParticipantEntity extends DBModel {
    citizenCardInfo: EXMParticipantCitizenCardInfo
    personalInfo: EXMParticipantPersonalInfo
    address: EXMParticipantAddress
    checkins: CheckIn[]
    eventId?: string
    contactNo?: string
    generatedId?: string
}

export interface CheckIn {
    eventId: string
    date: Date
}

export interface EXMParticipantCitizenCardInfo {
    idNumber: string
    issuerName: string
    issueNumber: string
    issueDate: Date
    expiryDate: Date
    photo?: string
}

export interface EXMParticipantPersonalInfo {
    name: {
        th: EXMParticipantName
        en: EXMParticipantName
    }
    gender: EXMParticipantGender
    age?: number
    dateOfBirth?: Date
}

export interface EXMParticipantName {
    title: string
    firstname: string
    middlename: string
    lastname: string
    nickname?: string
}

export enum EXMParticipantGender {
    Male = 'Male',
    Female = 'Female',
    Unspecified = 'Unspecified',
}
export interface EXMParticipantAddress {
    houseNumber: string
    street?: string
    road?: string
    alley?: string
    villageId?: string
    subDistrictId?: string
    districtId: string
    provinceId: string
    provinceName?: string
    districtName?: string
    subDistrictName?: string
    villageName?: string
}
