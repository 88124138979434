import React from 'react'
import {useObservableOrValue} from '../../../foundation/hooks/useObservableOrValue'
import {LabelProps, LabelTwolineProps} from './LabelIO'
import {FontVariants} from '../../../foundation/dls/sizes/generated.fonts'
import './Label.scss'
import {getSpec} from "../../../foundation/dls/styles/TextStyle";

export const Label: React.FC<LabelProps> = ({
                                                className,
                                                input: {
                                                    text$,
                                                    style,
                                                    color,
                                                    fontSize,
                                                    fontWeight,
                                                    fontFamily,
                                                    flex,
                                                    textAlign,
                                                    ellipsisTruncateNeeded,
                                                    onlyOneLine,
                                                    textOverflow,
                                                    overflow,
                                                    display
                                                },
                                            }) => {
    style = style ?? FontVariants.paragraph
    const text = useObservableOrValue(text$) ?? ''
    const spec = getSpec(style)
    const align = textAlign ?? 'start'

    const truncateStyle = ellipsisTruncateNeeded ? " truncated-text" : ""
    return (
        <div className={[className, spec.className].join(' ') + truncateStyle}
             color={color ?? spec.color}
             style={{
                 // @ts-ignore
                 textAlign: align,
                 fontSize: fontSize,
                 fontWeight: fontWeight,
                 fontFamily: fontFamily,
                 flex: flex,
                 whiteSpace: onlyOneLine ? 'nowrap' : 'normal',
                 textOverflow: textOverflow,
                 overflow: overflow,
                 alignItems: 'center',
                 display: display ?? 'flex',
                 justifyContent: align
             }}>
            {text}
        </div>
    )
}

export const LabelTwoLine: React.FC<LabelTwolineProps> = ({
                                                              input: {
                                                                  text$,
                                                                  label$,
                                                                  ...rest
                                                              },
                                                          }) => {
    return (
        <div className={'label-two-line-container'} {...rest}>
            <Label input={{
                text$: label$,
                style: FontVariants.subtitleBold,
                fontSize: '20px'
            }}/>
            <Label input={{
                text$: text$,
                style: FontVariants.small,
                fontSize: '20px'
            }}/>
        </div>
    )
}
