import { Observable } from 'rxjs'
import { useEffect } from 'react'

export function useSubscriptions(...obs: Observable<any>[]) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  obs.forEach(obs => useSubscription(obs))
}

// TODO: Change the body to the correct one, copy from EXM project
export function useSubscription<T>(obs: Observable<T>,
                                   exec?: (t: T) => void ,
                                   error?: (e: Error) => void,
                                   complete?: () => void,
                                   deps?: any[]) {
  useEffect(() => {
    const subs = obs.subscribe(
      o => (exec) ? exec(o) : null,
      o => (error) ? error(o) : null,
      () => (complete) ? complete() : null
    )
    return () => subs.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
