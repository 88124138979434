import {Observable, tap} from "rxjs";
import {AxiosResponse} from "axios";
import { ReportNetwork, ReportResponse } from '../network/ReportNetwork';

export class ReportUseCase {
    constructor(private network: ReportNetwork = new ReportNetwork()) {
    }

    getReport(eventID: string, type: 'CSV' | 'PDF'): Observable<ReportResponse> {
        return this.network.getReport(eventID, type)
    }

    regenerateReport(eventID: string): Observable<void> {
        return this.network.regenerateReport(eventID)
    }
}


