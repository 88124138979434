import {Label} from "../../components/base/Label/Label";
import {Toolbar} from "../../components/base/Toolbar/Toolbar";
import {Spacer} from "../../containers/Spacer/Spacer";
import {FontVariants} from "../../foundation/dls/sizes/generated.fonts";
import {SizeVariants} from "../../foundation/dls/sizes/generated.sizes";
import './ViewParticipants.scss'
import {TableView} from "../../components/base/Table/Table";
import React, {useEffect, useMemo, useState} from "react";
import {ViewParticipantsScene} from "./ViewParticipantsScene";
import {TextField, TextFieldType} from "../../components/base/TextField/TextField";
import {BehaviorSubject, shareReplay} from "rxjs";
import {Button} from "../../components/base/Button/Button";
import {Backdrop, CircularProgress} from "@mui/material";

export const ViewParticipantsPage: React.FC<any> = () => {
    const {input, output} = useMemo(() => ViewParticipantsScene(), [])
    const [selectedParticipant, setSelectedParticipant] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const buttonDisabled$ = useMemo(() => new BehaviorSubject<boolean>(true), [])
    const [currentParticipant, setParticipant] = useState<string[][]>([])
    useEffect(() => {
        const s1 = output.error$.subscribe((error) => {
            console.log("Error = " + JSON.stringify(error))
            setIsLoading(false)
        })

        const s2 = output.success$.subscribe(() => {
            setIsLoading(false)
            input.searchParticipant1$.next("")
            input.searchParticipant2$.next("")
            input.searchParticipant3$.next("")
            input.searchParticipant4$.next("")
            input.searchParticipant5$.next("")

        })
        return () => {
            s1.unsubscribe()
            s2.unsubscribe()
        }
    }, [input.searchParticipant1$, input.searchParticipant2$, input.searchParticipant3$, input.searchParticipant4$, input.searchParticipant5$, output.error$, output.participants$, output.success$])


    return (
        <div className={'admin-parent-container'}>
            <Toolbar input={{title: "รายชื่อผู้เข้าร่วมกิจกรรม"}}/>
            <div className={'admin-body'}>
                <div className={'card'}>
                    <div className={'title-container'}>
                        <Label input={{
                            text$: 'รายชื่อทั้งหมดของผู้เข้าร่วมกิจกรรม ',
                            style: FontVariants.heading,
                            textAlign: 'center'
                        }}/>
                    </div>
                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={isLoading}
                    >
                        <div className={'loading-card'}>
                            <CircularProgress/>
                            <Spacer size={SizeVariants.$xxl_spacing}/>
                            <Spacer size={SizeVariants.$l_spacing}/>
                            <Label input={{
                                text$: 'กำลังโหลด',
                                style: FontVariants.subtitleBold,
                            }}/>
                        </div>
                    </Backdrop>
                    <Spacer size={SizeVariants.$xxs_spacing}/>
                    <div className={'view-event-textfield'}>
                        <TextField
                            input={{
                                name: 'name',
                                type: TextFieldType.search,
                                isMandatory: false,
                                text$: input.searchParticipant1$.pipe(shareReplay(0)),
                                label$: 'ค้นหา (1)',
                                placeholder$: 'ค้นหาโดยใช้ ชื่อ, นามสกุล, เลขบัตรประชาชน, ที่อยู่'
                            }}
                            output={{
                                text$: input.searchParticipant1$
                            }}
                        />
                        <TextField
                            input={{
                                name: 'name',
                                type: TextFieldType.search,
                                isMandatory: false,
                                text$: input.searchParticipant2$.pipe(shareReplay(0)),
                                label$: 'ค้นหา (2)',
                                placeholder$: 'ค้นหาโดยใช้ ชื่อ, นามสกุล, เลขบัตรประชาชน, ที่อยู่'
                            }}
                            output={{
                                text$: input.searchParticipant2$
                            }}
                        />
                        <TextField
                            input={{
                                name: 'name',
                                type: TextFieldType.search,
                                isMandatory: false,
                                text$: input.searchParticipant3$.pipe(shareReplay(0)),
                                label$: 'ค้นหา (3)',
                                placeholder$: 'ค้นหาโดยใช้ ชื่อ, นามสกุล, เลขบัตรประชาชน, ที่อยู่'
                            }}
                            output={{
                                text$: input.searchParticipant3$
                            }}
                        />
                        <TextField
                            input={{
                                name: 'name',
                                type: TextFieldType.search,
                                isMandatory: false,
                                text$: input.searchParticipant4$.pipe(shareReplay(0)),
                                label$: 'ค้นหา (4)',
                                placeholder$: 'ค้นหาโดยใช้ ชื่อ, นามสกุล, เลขบัตรประชาชน, ที่อยู่'
                            }}
                            output={{
                                text$: input.searchParticipant4$
                            }}
                        />
                        <TextField
                            input={{
                                name: 'name',
                                type: TextFieldType.search,
                                isMandatory: false,
                                text$: input.searchParticipant5$.pipe(shareReplay(0)),
                                label$: 'ค้นหา (5)',
                                placeholder$: 'ค้นหาโดยใช้ ชื่อ, นามสกุล, เลขบัตรประชาชน, ที่อยู่'
                            }}
                            output={{
                                text$: input.searchParticipant5$
                            }}
                        />
                    </div>
                    <Spacer size={SizeVariants.$l_spacing}/>
                    <TableView
                        input={{
                            header: ['วันที่ร่วมกิจกรรม', 'ชื่อกิจกรรม', 'เลขบัตรประชาชน', 'รหัสส่วนตัว', 'ชื่อ-นามสกุล', 'เพศ', 'วัน / เดือน / ปี เกิด', 'ที่อยู่', 'บัตรหมดอายุ'],
                            content$: output.participants$,
                            hasSelect: true,
                            maxPage$: output.pageSize$
                        }}
                        output={{
                            selectedAction: (ids: string[]) => {
                                setSelectedParticipant(ids)
                                buttonDisabled$.next(ids.length < 1 || ids.length > 14)
                            },
                            setPage: (page) => {
                                input.setPage$.next(page)
                                // console.log("Current page = " + page)
                            }
                        }}/>
                    <Spacer size={SizeVariants.$xl_spacing}/>
                    <Button
                        input={{
                            type: 'button',
                            text$: 'เพิ่มผู้เข้าร่วมเข้า Prefilled Event',
                            disabled$: buttonDisabled$.asObservable(),
                        }}
                        output={{
                            click$: () => {
                                setIsLoading(true)
                                input.submit$.next(selectedParticipant)
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

