import {Scene} from '../../foundation/mvvm/Scene'
import {BehaviorSubject, Subject} from 'rxjs'
import {ViewParticipantsPageInput, ViewParticipantsPageOutput} from './ViewParticipantsIO'
import { ViewParticipantsVM } from './ViewParticipantsVM'

export const ViewParticipantsScene = (): Scene<ViewParticipantsPageInput, ViewParticipantsPageOutput, ViewParticipantsVM> => {
    const vm = new ViewParticipantsVM()
    const input: ViewParticipantsPageInput = {
        searchParticipant1$: new BehaviorSubject<string>(''),
        searchParticipant2$: new BehaviorSubject<string>(''),
        searchParticipant3$: new BehaviorSubject<string>(''),
        searchParticipant4$: new BehaviorSubject<string>(''),
        searchParticipant5$: new BehaviorSubject<string>(''),
        setPage$: new BehaviorSubject<number>(0),
        submit$: new Subject<string[]>(),
    }

    return {
        vm,
        input: input,
        output: vm.transform(input)
    }
}
