import {defer, from, Observable} from 'rxjs'
import {SIT_URL} from "../../utility/Axios/AxiosHandler";
import {encryptStorage, StorageKey} from "../../utility/storage";

export class ReportNetwork {
    getReport(eventId: string, type: 'CSV' | 'PDF'): Observable<ReportResponse> {
        const accessToken = encryptStorage.getItem<string>(StorageKey.accessToken)
        const username = encryptStorage.getItem<string>(StorageKey.username)
        const body = {
            authUsername: username ?? '',
            authentication: accessToken ?? '',
            eventId: eventId,
            reportType: type,
            shouldExportSupplementDocuments: true
        }
        const req = new Request(SIT_URL + "/getReport", {
            method: 'POST',
            body: JSON.stringify(body)
        });
        return defer(() => from(fetch(req).then(response => response.json()).then(response => {
                if (response.httpCode === 500) {
                    // console.log("ERROR!! = " + JSON.stringify(response))
                    throw new Error('Cant Gen');
                }
                // console.log('------Response from fetch = ' + JSON.stringify(response))
                return response.data
            })
        ))
    }

    regenerateReport(eventId: string): Observable<void> {
        const accessToken = encryptStorage.getItem<string>(StorageKey.accessToken)
        const username = encryptStorage.getItem<string>(StorageKey.username)
        const body = {
            authUsername: username ?? '',
            authentication: accessToken ?? '',
            eventRefNo: eventId,
        }
        const req = new Request(SIT_URL + "/regenerateConsentDocument", {
            method: 'POST',
            body: JSON.stringify(body)
        });
        return defer(() => from(fetch(req).then(response => response.json()).then(response => {
                if (response.httpCode === 500) {
                    // console.log("ERROR!! = " + JSON.stringify(response))
                    throw new Error('Cant Gen');
                }
                // console.log('------Response from fetch = ' + JSON.stringify(response))
                return response.data
            })
        ))
    }
}

export interface ReportResponse {
    base64?: string
    header?: string[]
    body?: []
}
