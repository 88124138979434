import React from "react";
import {FontVariants} from "../../../foundation/dls/sizes/generated.fonts";
import {useObservableOrValue} from "../../../foundation/hooks/useObservableOrValue";
import {getSpec} from "../../../foundation/dls/styles/TextStyle";
import {ErrorBarProps} from "./ErrorBarIO";
import "./ErrorBar.scss"
import {Label} from "../Label/Label";
import {ColorNames} from "../../../foundation/dls/theme/generated.colors";
import {Alert, AlertTitle, Snackbar } from "@mui/material";

export const ErrorBar: React.FC<ErrorBarProps> = ({
                                                      input,
                                                      ...rest
                                                  }) => {
    const text = useObservableOrValue(input.text$) ?? null
    const isError = useObservableOrValue(input.error$) ?? false
    const shouldShow =  isError
    if (shouldShow) {
        return <Alert severity="error">
            <AlertTitle>ล็อกอินผิดพลาด</AlertTitle>
            โปรดตรวจสอบชื่อผู้ใช้และรหัสผ่านแล้วลองอีกครั้ง
            </Alert>
    } else {
        return null
    }
}
