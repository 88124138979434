import {SubjectOrCallback} from "../../foundation/rx/ObservableOrValue";
import {Subject} from "rxjs";

export const handleSubjectOrCallbackAction = (fn: SubjectOrCallback<void> | undefined) => {
    if (fn == null) return
    if (fn instanceof Subject) {
        fn.next()
    } else {
        fn()
    }
}

export const handleSubjectOrCallbackActionValue = (fn: SubjectOrCallback<any> | undefined , value: any | undefined ) => {
    if (fn == null) return
    if (fn instanceof Subject) {
        fn.next(value)
    } else {
        fn(value)
    }
}

export function forceRerender(state: any) {
    setTimeout(() => {
        state(Math.random())
    }, 1)
}