import {Observable} from 'rxjs';

export interface LoadingInput {
    type: LoadingCase
    isLoading: Observable<boolean>
}

export interface LoadingProps {
    input: LoadingInput
}

export enum LoadingCase {
    generic,
    login,
    document
}