import {VM} from "../../foundation/mvvm/VM";
import {
    BehaviorSubject,
    combineLatestWith,
    distinctUntilChanged,
    map,
    mergeMap,
    Observable,
    retry,
    shareReplay,
    Subject
} from "rxjs";
import {ErrorTracker, trackError} from "../../utility/rx/ErrorTracker";
import {ViewParticipantsPageInput, ViewParticipantsPageOutput} from "./ViewParticipantsIO";
import {EventUseCase, mapParticipantModelsToTableDisplay} from "../../models/usecases/EventUseCase";

import {ParticipantUseCase} from "../../models/usecases/ParticipantUseCase";
import {EXMParticipantEntity} from "../../models/network/ParticipantNetwork";
import {EventModel} from "../../models/network/EventNetwork";

export class ViewParticipantsVM implements VM<ViewParticipantsPageInput, ViewParticipantsPageOutput> {
    constructor(private useCase: ParticipantUseCase = new ParticipantUseCase(),
                private eventUseCase: EventUseCase = new EventUseCase()) {
    }

    transform(input: ViewParticipantsPageInput): ViewParticipantsPageOutput {
        const participantSubject = new Subject<string[][]>()
        const pageSizeSubject = new Subject<number>()
        const successSubject$ = new Subject<void>()
        const eventError$ = new ErrorTracker()
        let currentSearchCount = 0
        let allParticipants: EXMParticipantEntity[] = []
        let allEvents: EventModel[] = []

        function refresh(currentCount: number, useCase: ParticipantUseCase, eventUseCase: EventUseCase, search1?: string, search2?: string, search3?: string, search4?: string, search5?: string, currentPage?: number) {
            useCase.getAllParticipantList(search1, search2, search3, search4, search5, currentPage).pipe(
                mergeMap((participants) => {
                    return eventUseCase.getEventList().pipe(
                        map(events => {
                            return {
                                participants: participants,
                                events: events,
                            }
                        })
                    )
                }),
                trackError(eventError$),
                retry(0),
                shareReplay(0),
            ).subscribe((item) => {
                if (currentCount !== currentSearchCount) return
                allParticipants = item.participants.participantList
                allEvents = item.events.events
                pageSizeSubject.next(item.participants.pageSize)
                participantSubject.next(mapParticipantModelsToTableDisplay(item.participants.participantList, item.events.events))
            })
        }

        //on load at first time
        // setTimeout(() => {
            // input.setPage$.subscribe()
        //     refresh(this.useCase, this.eventUseCase)
        // }, 10)


        input.searchParticipant1$.pipe(combineLatestWith(input.searchParticipant2$, input.searchParticipant3$, input.searchParticipant4$, input.searchParticipant5$, input.setPage$)).subscribe(([s1, s2, s3, s4, s5, page]) => {
            // const textList = text.filter((t) => {
            //     return t !== ''
            // })
            let search1: string | undefined = s1
            let search2: string | undefined = s2
            let search3: string | undefined = s3
            let search4: string | undefined = s4
            let search5: string | undefined = s5
            currentSearchCount = currentSearchCount + 1
            refresh(currentSearchCount, this.useCase, this.eventUseCase, search1, search2, search3, search4, search5, page)
            // if (textList.length === 0) {
            //     participantSubject.next(mapParticipantModelsToTableDisplay(allParticipants, allEvents))
            // } else {
            //     let filteredParticipant = allParticipants
            //     textList.forEach(filterText => {
            //         filteredParticipant = filteredParticipant.filter(data => {
            //             return data.eventId?.toLowerCase().includes(filterText.toLowerCase())
            //                 || data.personalInfo.name.th.firstname.includes(filterText.toLowerCase())
            //                 || data.personalInfo.name.th.middlename.includes(filterText.toLowerCase())
            //                 || data.personalInfo.name.th.lastname.includes(filterText.toLowerCase())
            //                 || data.personalInfo.name.th.title.includes(filterText.toLowerCase())
            //                 || data.personalInfo.name.th.nickname?.includes(filterText.toLowerCase())
            //                 || data.personalInfo.name.th.firstname.includes(filterText.toLowerCase())
            //                 || data.address.provinceName?.includes(filterText.toLowerCase())
            //                 || data.address.districtName?.includes(filterText.toLowerCase())
            //                 || data.address.subDistrictName?.includes(filterText.toLowerCase())
            //                 || data.address.villageName?.includes(filterText.toLowerCase())
            //                 || data.contactNo?.includes(filterText.toLowerCase())
            //         })
            //     })
            //     participantSubject.next(mapParticipantModelsToTableDisplay(filteredParticipant, allEvents))
            // }
        })

        input.submit$.subscribe((ids) => {
            this.eventUseCase.addParticipantsToPrefilledEvent(ids).subscribe((response) => {
                successSubject$.next()
            })
        })

        return {
            participants$: participantSubject.asObservable(),
            error$: eventError$,
            success$: successSubject$.asObservable(),
            pageSize$: pageSizeSubject.asObservable()
        }
    }
}
