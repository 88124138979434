import { EMPTY, Observable, of, Subject } from 'rxjs'
import { catchError, retry, tap } from 'rxjs/operators'
import { ObservableType } from '../../foundation/mvvm/ObservableType'

export class ErrorTracker extends Observable<Error> {
  private subject = new Subject<Error>()

  // TODO: Make this default value goes by environment setup
  logTrackedError = true

  trackError<T>(source: ObservableType<T>): Observable<T> {
    return source.pipe(
      tap({ error: error => this.subject.next(error) }),
      tap({
            error: error => {
              if (!this.logTrackedError) return
              console.log('Tracked error occurred')
              console.log('------------------------------------')
              console.error(error)
              console.log('------------------------------------')
            }
          })
    )
  }

  // @ts-ignore
  get source(): Observable<any> {
    return this.subject
  }
}

export function trackError<T>(tracker: ErrorTracker): (source: ObservableType<T>) => Observable<T> {
  return source => tracker.trackError(source)
}

export function extractErrorOnErrorJustComplete<T>(tracker: ErrorTracker): (source: ObservableType<T>) => Observable<T> {
  return source => tracker.trackError(source).pipe(
    catchError(() => EMPTY)
  )
}

export function extractErrorOnErrorRetry<T>(tracker: ErrorTracker,
                                            retryCount: number = -1): (source: ObservableType<T>) => Observable<T> {
  return source => tracker.trackError(source).pipe(
    retry(retryCount)
  )
}

export function extractErrorOnErrorReturn<T>(tracker: ErrorTracker,
                                             ret: T): (source: ObservableType<T>) => Observable<T> {
  return source => tracker.trackError(source).pipe(
    catchError(() => of(ret))
  )
}

export function extractErrorOnErrorReplace<T>(tracker: ErrorTracker,
                                              ret: Observable<T>): (source: ObservableType<T>) => Observable<T> {
  return source => tracker.trackError(source).pipe(
    catchError(() => ret)
  )
}
