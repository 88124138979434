import {LoginToolbarProps} from "./LoginToolbarIO";
import React from 'react';
import './Toolbar.scss'
import {useNavigate} from "react-router-dom";
import {RoutesName, RouteWithParams} from "../../../utility/RoutesName/RoutesName";
import {getValueOfKey} from "../../../utility/tokenManager";
import {Label} from '../Label/Label';
import {FontVariants} from '../../../foundation/dls/sizes/generated.fonts';
import {ColorNames} from '../../../foundation/dls/theme/generated.colors';
import {Spacer} from '../../../containers/Spacer/Spacer';
import {SizeVariants} from '../../../foundation/dls/sizes/generated.sizes';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import {clearStorage, encryptStorage, StorageKey, Role} from "../../../utility/storage";
import { EXMDialog } from "../Dialog/Dialog";

export const Toolbar: React.FC<LoginToolbarProps> = ({
                                                         input,
                                                         output
                                                     }) => {
    const navigate = useNavigate()
    const enableNavigation = input?.isEnable ?? true

    const logoutFunction = () => {
        clearStorage()
        navigate(RoutesName.loginPage)
    }

    return (
        <div className={'toolbar'}>
            <img src={'/assets/icon.png'}
                 className={'app-logo-toolbar'}
                 onClick={() => {
                     navigate(RoutesName.home)
                 }} alt={'Home-Logo'}/>
            <Spacer size={SizeVariants.$s_spacing}/>

            <Label input={{
                text$: input?.title ?? '',
                style: FontVariants.subtitle,
                textAlign: 'center',
                color: ColorNames.text3
            }}/>

            <div className={'profile'}>
                <div className={'info-section'}>
                    <Label input={{
                        text$: 'Event Manager',
                        style: FontVariants.paragraph,
                        textAlign: 'flex-end',
                        color: ColorNames.text2
                    }}/>
                    <Label input={{
                        text$: 'ระบบหลังบ้าน',
                        style: FontVariants.paragraph,
                        textAlign: 'flex-end',
                        color: ColorNames.text2
                    }}/>
                </div>
                <Spacer size={SizeVariants.$m_spacing}/>
                <EXMDialog
                    input={{title: 'คำเตือน', description: 'ต้องการออกจากระบบใช่หรือไม่'}}
                    output={{onConfirm: logoutFunction}}
                />

            </div>
        </div>
    )
}
