import {Label} from "../../components/base/Label/Label";
import {Toolbar} from "../../components/base/Toolbar/Toolbar";
import {Spacer} from "../../containers/Spacer/Spacer";
import {FontVariants} from "../../foundation/dls/sizes/generated.fonts";
import {SizeVariants} from "../../foundation/dls/sizes/generated.sizes";
import './ViewEvents.scss'
import {TableView} from "../../components/base/Table/Table";
import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';
import React, {useEffect, useMemo} from "react";
import {
    Alert,
    AlertTitle,
    Backdrop,
    CircularProgress,
    Snackbar,
} from "@mui/material";
import csvDownload from "json-to-csv-export";
import {ViewEventScene} from "./ViewEventScene";
import moment from "moment";
import {TextField, TextFieldType} from "../../components/base/TextField/TextField";
import {shareReplay} from "rxjs";

export const ViewEventPage: React.FC<any> = () => {
    const {input, output} = useMemo(() => ViewEventScene(), [])

    const [isLoading, setIsLoading] = React.useState(false);
    const [openDownloadSuccessful, setDownloadSuccessful] = React.useState(false);
    const [openDownloadError, setDownloadError] = React.useState(false);

    useEffect(() => {
        const s1 = output.error$.subscribe((error) => {
            console.log("Error = " + JSON.stringify(error))
            setDownloadError(true)
            setIsLoading(false)
        })

        const s2 = output.exportCSVResult$.subscribe((data) => {
            const dataToConvert = {
                data: data.body,
                filename: `Regis CSV Report - ${data.eventName} - (${moment().format('DDMMYYYY')})`,
                delimiter: ',',
                headers: data.header
            }
            csvDownload(dataToConvert)
            setIsLoading(false)
            setDownloadSuccessful(true)
        })

        const s3 = output.exportPDFResult$.subscribe((value) => {
            const downloadLink = document.createElement("a");
            downloadLink.href = `data:application/pdf;base64,${value.pdf}`;
            downloadLink.download = `Regis Report - ${value.eventName} - (${moment().format('DDMMYYYY')}).pdf`;
            downloadLink.click();
            setIsLoading(false)
            setDownloadSuccessful(true)
        })

        const s4 = output.exportError$.subscribe(_ => {
            setDownloadError(true)
            setIsLoading(false)
        })

        return () => {
            s1.unsubscribe()
            s2.unsubscribe()
            s3.unsubscribe()
            s4.unsubscribe()
        }
    }, [])


    return (
        <div className={'view-event-parent-container'}>
            <Snackbar open={openDownloadSuccessful} autoHideDuration={6000} onClose={() => setDownloadSuccessful(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert severity="success" variant="filled" elevation={6}>
                    <AlertTitle>ดาวน์โหลดสำเร็จ</AlertTitle>
                    สร้างรายงานและส่งออกเรียบร้อยแล้ว
                </Alert>
            </Snackbar>
            <Snackbar open={openDownloadError} autoHideDuration={6000} onClose={() => setDownloadError(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert severity="error" variant="filled" elevation={6}>
                    <AlertTitle>ไม่สามารถสร้างไฟล์</AlertTitle>
                    อาจเป็นเพราะกิจกรรมที่เลือก<br/>
                    ไม่มีผู้เข้าร่วม โปรดลองอีกครั้งในภายหลัง
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <div className={'loading-card'}>
                    <CircularProgress/>
                    <Spacer size={SizeVariants.$xxl_spacing}/>
                    <Spacer size={SizeVariants.$l_spacing}/>
                    <Label input={{
                        text$: 'กำลังโหลด',
                        style: FontVariants.subtitleBold,
                    }}/>
                </div>
            </Backdrop>
            <Toolbar input={{title: "ข้อมูลกิจกรรม"}}/>
            <div className={'view-event-body'}>
                <div className={'card'}>
                    <div className={'title-container'}>
                        <ContactPageRoundedIcon/>
                        <Spacer size={SizeVariants.$s_spacing}/>
                        <Label input={{
                            text$: 'ข้อมูลกิจกรรม',
                            style: FontVariants.subtitleBold,
                        }}/>
                    </div>
                    <TextField
                        input={{
                            name: 'name',
                            type: TextFieldType.search,
                            isMandatory: false,
                            text$: input.searchEvent$.pipe(shareReplay(0)),
                            label$: 'ค้นหา',
                            placeholder$: 'ค้นหาโดยใช้ ชื่อกิจกรรม'
                        }}
                        output={{
                            text$: input.searchEvent$
                        }}
                    />
                    <Spacer size={SizeVariants.$m_spacing}/>
                    <TableView
                        input={{
                            header: ['วันที่กิจกรรม', 'ชื่อกิจกรรม', 'จำนวนผู้เข้าร่วม'],
                            content$: output.events$,
                            hasDownloadOption: true
                        }}
                        output={{
                            downloadCSV: (id) => {
                                setIsLoading(true)
                                input.exportCSV$.next(id)
                            },
                            downloadPDF: (id) => {
                                setIsLoading(true)
                                input.exportPDF$.next(id)
                            },
                            regeneratePDF: (id) => {
                                setIsLoading(true)
                                input.regeneratePDF$.next(id)
                                setTimeout(() => {
                                    setIsLoading(false)
                                }, 1000 * 60 * 3)
                            },
                        }}/>
                </div>
            </div>
        </div>
    )

}

