import React, {CSSProperties} from 'react'
import {Size, SizeMultiplier, SizeVariants} from '../../foundation/dls/sizes/generated.sizes'

export interface SpacerProps {
  size?: SizeVariants
  sizeRem?: string
}

export const Spacer: React.FC<SpacerProps> = ({ size, sizeRem }) => {

  const style: CSSProperties = {
    width: size ?? sizeRem ?? Size.rem(SizeMultiplier.m),
    height: size ?? sizeRem ?? Size.rem(SizeMultiplier.m)
  }
  return (
    <div className="spacer" style={style}/>
  )
}
