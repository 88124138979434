import React, { CSSProperties } from 'react'
import { FlexProps } from './FlexIO'

export const Flex: React.FC<FlexProps> = ({
                                            row,
                                            input,
                                            style,
                                            children,
                                            ...rest
                                          }) => {
  const baseStyle: CSSProperties = {
    display: 'flex',
    flexDirection: row ? 'row' : input?.flexDirection ?? 'column',
    justifyContent: input?.justifyContent,
    alignItems: input?.alignItems,
    padding: input?.padding,
    margin: input?.margin,
    flexGrow: input?.flexGrow ?? 1
  }
  style = Object.assign(style ?? {}, baseStyle)
  return (
    <div style={style} {...rest}>
      {children}
    </div>
  )
}
