import {defer, EMPTY, from, last, Observable, of} from 'rxjs'
import axios, {AxiosResponse} from "axios";
import {axiosHandler, AxiosHandler, SIT_URL} from "../../utility/Axios/AxiosHandler";
import {encryptStorage, StorageKey} from "../../utility/storage";
import {Buffer} from "buffer";

export class LoginNetwork {

    login(username: string, password: string): Observable<any> {
        document.body.style.cursor = 'progress'
        const body = {
            username: username,
            password: password,
        }
        const req = new Request(SIT_URL + "/login", {
            method: 'POST',
            body: JSON.stringify(body)
        });
        return defer(() => from(fetch(req).then(response => {
            if (response.redirected) {
                throw  new Error('need to redirect')
            } else {
                return response
            }
        }).then(response => response.json()).then(response => {
            if (response.token) {
                // console.log("Login success")
                // let decodeToken = JSON.parse(decodeString(response.token))
                encryptStorage.setItem(StorageKey.accessToken, response.token)
                encryptStorage.setItem(StorageKey.username, username)
                return response;
            } else if (response.httpCode === 500) {
                // console.log("ERROR!! = " + JSON.stringify(response))
                throw new Error(response.code);
            }
        })
            .finally(() => {
                document.body.style.cursor = 'default'
            })))
    }


}

function encodeString(fromText: string): string {
    return Buffer.from(fromText, 'binary').toString('base64')
}

function decodeString(fromText: string): string {
    return Buffer.from(fromText, 'base64').toString('binary')
}

export interface LoginResponse {

}

export const isUserLoggedIn = () => {
    return encryptStorage.getItem(StorageKey.accessToken) !== undefined
}
