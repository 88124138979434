import { EncryptStorage } from 'encrypt-storage';

// Example of secret_key variable in an .env file
// const encryptStorage = new EncryptStorage(process.env.SECRET_KEY, options);
export const encryptStorage = new EncryptStorage('Smart-Elcid', {
    storageType: 'localStorage'
});

export enum StorageKey {
    accessToken = 't1',
    username = 't2'
}

export const clearStorage = () => {
    encryptStorage.clear()
}

export const isValidToken = ():boolean => {
    return encryptStorage.getItem(StorageKey.accessToken) != null
}

export enum Role {
    super_admin = "SUPER_ADMIN",
    admin = "ADMIN",
    member = "MEMBER"
}
