import axios, {AxiosInstance} from "axios";
import {clearStorage, encryptStorage, StorageKey} from "../storage";
import {ajax} from "rxjs/ajax";
import {RoutesName, RouteWithParams} from "../RoutesName/RoutesName";
import {notificationError} from "../ErrorNoti/ErrorNotification";

// export const SIT_URL = "http://localhost:3000"
// export const SIT_URL = "https://regis.epcs.co.th"
export const SIT_URL = "https://regisune.epcs.co.th"
export class AxiosHandler {

    static instance = axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true'
        },
        timeout: 600000,
        baseURL: SIT_URL
    })

    static withAuthInstance(showError: boolean = true, isFormData: boolean = false, timeout: number = 15000): AxiosInstance { // this will add Authorization header
        // const accessToken = encryptStorage.getItem<string>(StorageKey.accessToken)
        // const username = encryptStorage.getItem<string>(StorageKey.username)

        const axiosAuthInstance = axios.create({
            headers: {
                'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
            },
            timeout: timeout,
            baseURL: SIT_URL
        })

        axiosAuthInstance.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            // console.log("Log with interceptor response = " + JSON.stringify(response))
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            // console.log("Log with interceptor error = " + JSON.stringify(error))
            // console.log(JSON.stringify(error.response))
            if (error.response?.status) {
                let status = error.response.status ?? 9999
                if (status === 401) {
                    // console.log("Access token = " + encryptStorage.getItem(StorageKey.accessToken))
                    if (encryptStorage.getItem(StorageKey.accessToken) !== undefined) {
                        clearStorage()
                        window.location.replace(RoutesName.loginWithError)
                    } else {
                        window.location.replace(RoutesName.loginNormal)
                    }

                }
            }
            // console.log("Error = " + JSON.stringify(error.response))
            if (showError) {
                // console.log("SHOW ERROR!!" + JSON.stringify(error.response?.data))
                notificationError(JSON.stringify(error.response?.status), error.response?.data?.message.substring(0, 30))
            }
            return Promise.reject(error);
        })
        return axiosAuthInstance
    }
}

export const axiosHandler = () => {
    const accessToken = encryptStorage.getItem<string>(StorageKey.accessToken)
    const username = encryptStorage.getItem<string>(StorageKey.username)
    const instance = axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'username': username ?? "",
            'Authorization': accessToken ?? ''
        },
        timeout: 6000000,
        baseURL: SIT_URL
    })


    return instance
}
