import {Scene} from '../../foundation/mvvm/Scene'
import {BehaviorSubject, Subject} from 'rxjs'
import { ViewEventPageOutput } from './ViewEventIO'
import { ViewEventVM } from './ViewEventVM'

interface ViewEventSceneInput {
    searchEvent$: BehaviorSubject<string>
    exportCSV$: Subject<string>
    exportPDF$: Subject<string>
    regeneratePDF$: Subject<string>
}

export const ViewEventScene = (): Scene<ViewEventSceneInput, ViewEventPageOutput, ViewEventVM> => {
    const vm = new ViewEventVM()
    const input: ViewEventSceneInput = {
        searchEvent$: new BehaviorSubject<string>(''),
        exportCSV$: new Subject<string>(),
        exportPDF$: new Subject<string>(),
        regeneratePDF$: new Subject<string>(),
    }

    return {
        vm,
        input: input,
        output: vm.transform(input)
    }
}
