import React, {useRef} from "react";
import {TextFieldProps} from "./TextFieldIO";
import {useObservableOrValue} from "../../../foundation/hooks/useObservableOrValue";
import {useT} from "../../../foundation/hooks/useT";
import {filter} from "rxjs/operators";
import './TextField.scss'
import {Label} from "../Label/Label";
import {FontVariants} from "../../../foundation/dls/sizes/generated.fonts";
import {ColorNames} from "../../../foundation/dls/theme/generated.colors";
import {Button} from '../Button/Button';
import {ButtonStyle} from '../../../foundation/dls/styles/ButtonStyle';
import {handleSubjectOrCallbackAction, handleSubjectOrCallbackActionValue} from '../../../utility/rx/rxHandler';
import {notificationSuccess} from "../../../utility/ErrorNoti/ErrorNotification";
import { toast } from "react-toastify";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

export enum TextFieldType {
    normal,
    borderless,
    multiLine,
    grey,
    withPrefix,
    withPrefixIcon,
    withoutLabelAndNumericOnly,
    table,
    search
}

export enum TextFieldTitleType {
    normal,
    withThaiSuggestion,
    withEngSuggestion
}

export const TextFieldMultiline: React.FC<TextFieldProps> = ({
                                                                 input,
                                                                 output,
                                                                 ...rest
                                                             }) => {


    var text = useObservableOrValue(input.text$)
    const ref = useRef(input.name)
    if (input.type === TextFieldType.multiLine) {

    }

    if (text === undefined && input.startText && input.startText !== '') {
        text = input.startText
    }

    if (text === undefined) {
        text = ""
    }


    const titleType = input.titleType ?? TextFieldTitleType.normal
    const titleLabel = useObservableOrValue(input.label$) ?? ""
    const descriptionLabel = useObservableOrValue(input.descriptionLabel$) ?? null
    const prefixText = useObservableOrValue(input.prefixText$) ?? ""
    const prefixIcon = useObservableOrValue(input.prefixIcon$) ?? ""
    const isError = useObservableOrValue(input.error$)
    const errorInlineText = useObservableOrValue(input.errorInLineText$) ?? ""
    const t = useT()
    const disable = useObservableOrValue(input.disable$) ?? false
    const placeholder = useObservableOrValue(input.placeholder$, {
        whenObs: o => o.pipe(filter(o => o !== ''))
    }) ?? ''

    const focusAction = (isFocus: boolean) => {
    }

    const textFieldLabelComponent = () => {
        if (input.label$ == null) return null
        switch (input.type) {
            case TextFieldType.withoutLabelAndNumericOnly: {
                return null
            }
            default: {
                return <div className={'outer-label-container'}>
                    <div className={'inner-label-container'}>
                        <label className={'mandatory-field'}> *
                            <label className={'text-field-input-label'}> {titleLabel}</label>
                        </label>
                        {input.titleType === TextFieldTitleType.withThaiSuggestion ?
                            <Label className={'mandatory-field-1'}
                                   input={{
                                       text$: "(กรุณากรอกเป็นภาษาไทย)",
                                       style: FontVariants.subtitleBold,
                                       color: ColorNames.primary,
                                       fontSize: "20px"
                                   }}/> : null}
                        {input.titleType === TextFieldTitleType.withEngSuggestion ?
                            <Label
                                input={{
                                    text$: "(กรุณากรอกเป็นภาษาอังกฤษ)",
                                    style: FontVariants.subtitleBold,
                                    color: ColorNames.primary,
                                    fontSize: "20px"
                                }}/> : null}
                    </div>
                    {descriptionLabel != null ?
                        <Label className={'description-label'}
                               input={{
                                   text$: descriptionLabel,
                                   style: FontVariants.subtitle,
                                   color: ColorNames.primary,
                                   fontSize: "11px"
                               }}/> : null}
                </div>
            }
        }
    }

    const textFieldComponent = () => {
        switch (input.type) {
            case TextFieldType.multiLine: {
                return (
                    <div className='text-field-new-multiline'>
                        <textarea className={'text-field-input-multiline'}
                                  name={input.name}
                                  value={text}
                                  id={input.name}
                                  onFocus={() => focusAction(true)}
                                  onBlur={() => focusAction(false)}
                                  required
                                  placeholder={placeholder}
                                  onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                        />
                    </div>
                )
            }
            case TextFieldType.withPrefix: {
                return (
                    <div className='text-field-new-withPrefix-container'>
                        <div className='text-field-new-withPrefix-left-area'>
                            <Label
                                input={{
                                    text$: prefixText,
                                    style: FontVariants.subtitle,
                                    color: ColorNames.black,
                                    fontSize: "20px"
                                }}/>
                        </div>
                        <div className='text-field-new-withPrefix-right-area'>
                            <input className={'text-field-input-withPrefix'}
                                   name={input.name}
                                   value={text}
                                   required
                                   placeholder={placeholder}
                                   onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                                   onFocus={() => focusAction(true)}
                                   onBlur={() => focusAction(false)}
                            />
                        </div>
                    </div>
                )
            }
            case TextFieldType.withPrefixIcon: {
                return (
                    <div className='text-field-new-withPrefixIcon-container'>
                        <div className='text-field-new-withPrefixIcon-left-area'>
                            <img src={prefixIcon}
                                 className={'prefix-icon'}/>
                        </div>
                        <div className='text-field-new-withPrefixIcon-right-area'>
                            <input className={'text-field-input-withPrefixIcon'}
                                   name={input.name}
                                   value={text}
                                   required
                                   placeholder={placeholder}
                                   onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                                   onFocus={() => focusAction(true)}
                                   onBlur={() => focusAction(false)}
                            />
                        </div>
                        <Button
                            style={{alignSelf: 'bottom', width: '100%'}}
                            input={{
                                type: 'submit',
                                text$: 'คัดลอก',
                                style: ButtonStyle.textField,
                                // disabled$: false
                            }}
                            output={{
                                click$: () => {
                                    navigator.clipboard.writeText(text ?? '')
                                        .then(() => {
                                            toast.success("Copied to clipboard")
                                        })
                                        .catch(() => {
                                            toast.error("Failed to Copy")
                                        });
                                }
                            }}
                        />
                    </div>
                )
            }
            case TextFieldType.withoutLabelAndNumericOnly: {
                if (disable) {
                    return (
                        <div className='text-field-without-label-input-disable'>
                            <input className={'text-field-input-disable'}
                                   name={input.name}
                                   type={'tel'}
                                   onFocus={() => focusAction(true)}
                                   onBlur={() => focusAction(false)}
                                   value={text}
                                   required
                                   disabled={true}
                                   placeholder={placeholder}
                                   onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                           event.preventDefault();
                                       }
                                   }}
                                   onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                            />
                        </div>
                    )
                } else {
                    return (
                        <div className='text-field-without-label-input'>
                            <input className={'text-field-input'}
                                   name={input.name}
                                   onFocus={() => focusAction(true)}
                                   onBlur={() => focusAction(false)}
                                   type={'tel'}
                                   value={text}
                                   required
                                   placeholder={placeholder}
                                   onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                           event.preventDefault();
                                       }
                                   }}
                                   onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                            />
                        </div>
                    )
                }
            }
            case TextFieldType.grey:
                return (
                    <div className='text-field-grey-new'>
                        <input className={'text-field-input'}
                               name={input.name}
                               type={'text'}
                               id={input.name}
                               value={text}
                               required
                               placeholder={placeholder}
                               onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                               onFocus={() => focusAction(true)}
                               onBlur={() => focusAction(false)}
                        />
                    </div>
                )
            case TextFieldType.borderless:
                return (
                    <div className='text-field-borderless-new'>
                        <input className={'text-field-input'}
                               name={input.name}
                               type={'text'}
                               id={input.name}
                               value={text}
                               required
                               placeholder={placeholder}
                               onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                               onFocus={() => focusAction(true)}
                               onBlur={() => focusAction(false)}
                        />
                    </div>
                )
            default: {
                return (
                    <div className={disable ? 'text-field-new-disable' : 'text-field-new'}>
                        <input className={'text-field-input'}
                               name={input.name}
                               type={'text'}
                               id={input.name}
                               value={text}
                               required
                               placeholder={placeholder}
                               onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                               onFocus={() => focusAction(true)}
                               onBlur={() => {
                                   handleSubjectOrCallbackAction(output?.onBlur$)
                                   focusAction(false)
                               }}
                        />
                    </div>
                )
            }
        }
    }
    const inlineErrorComponent = () => {
        if (isError) {
            return <Label input={{
                text$: errorInlineText,
                style: FontVariants.small,
                color: ColorNames.errorRed
            }}/>
        } else {
            // return <Spacer size={SizeVariants.$m_spacing}/>

        }
    }

    return (
        <div className={'text-field-container'} {...rest}>
            {textFieldLabelComponent()}
            {textFieldComponent()}
            {inlineErrorComponent()}
        </div>
    )
}


export const TextField: React.FC<TextFieldProps> = ({
                                                        input,
                                                        output,
                                                        ...rest
                                                    }) => {


    var text = useObservableOrValue(input.text$)
    const ref = useRef(input.name)

    if (text === undefined && input.startText && input.startText !== '') {
        text = input.startText
    }

    if (text === undefined) {
        text = ""
    }
    const focusAction = (isFocus: boolean) => {
        handleSubjectOrCallbackActionValue(output.isFocus$, isFocus)
    }

    const titleType = input.titleType ?? TextFieldTitleType.normal
    const titleLabel = useObservableOrValue(input.label$) ?? ""
    const descriptionLabel = useObservableOrValue(input.descriptionLabel$) ?? null
    const prefixText = useObservableOrValue(input.prefixText$) ?? ""
    const prefixIcon = useObservableOrValue(input.prefixIcon$) ?? ""
    const isError = useObservableOrValue(input.error$)
    const errorInlineText = useObservableOrValue(input.errorInLineText$) ?? ""
    const t = useT()
    const disable = useObservableOrValue(input.disable$) ?? false
    const placeholder = useObservableOrValue(input.placeholder$, {
        whenObs: o => o.pipe(filter(o => o !== ''))
    }) ?? ''
    const isDisable = useObservableOrValue(input.disable$) ?? false
    const textFieldLabelComponent = () => {
        if (input.label$ == null) return null
        switch (input.type) {
            case TextFieldType.withoutLabelAndNumericOnly: {
                return null
            }
            default: {
                return <div className={'outer-label-container'}>
                    <div className={'inner-label-container'}>
                        <label className={'mandatory-field'}>
                            <label className={'text-field-input-label'}> {titleLabel}</label>
                        </label>
                    </div>
                </div>
            }
        }
    }

    const textFieldComponent = () => {
        switch (input.type) {
            case TextFieldType.multiLine: {
                return (
                    <div className='text-field-new-multiline'>
                        <textarea className={'text-field-input-multiline'}
                                  name={input.name}
                                  value={text}
                                  id={input.name}
                                  required
                                  placeholder={placeholder}
                                  onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                                  onFocus={() => focusAction(true)}
                                  onBlur={() => focusAction(false)}
                        />
                    </div>
                )
            }

            case TextFieldType.withPrefix: {
                return (
                    <div className='text-field-new-withPrefix-container'>
                        <div className='text-field-new-withPrefix-left-area'>
                            <Label
                                input={{
                                    text$: prefixText,
                                    style: FontVariants.subtitle,
                                    color: ColorNames.black,
                                    fontSize: "20px"
                                }}/>
                        </div>
                        <div className='text-field-new-withPrefix-right-area'>
                            <input className={'text-field-input-withPrefix'}
                                   name={input.name}
                                   value={text}
                                   required
                                   placeholder={placeholder}
                                   onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                                   onFocus={() => focusAction(true)}
                                   onBlur={() => focusAction(false)}
                            />
                        </div>
                    </div>
                )
            }

            case TextFieldType.withPrefixIcon: {
                return (
                    <div className='text-field-new-withPrefixIcon-container'>
                        <div className='text-field-new-withPrefixIcon-left-area'>
                            <img src={prefixIcon}
                                 className={'prefix-icon'}/>
                        </div>
                        <div className='text-field-new-withPrefixIcon-right-area'>
                            <input className={'text-field-input-withPrefixIcon'}
                                   name={input.name}
                                   value={text}
                                   disabled={isDisable}
                                   required
                                   placeholder={placeholder}
                                   onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                                   onFocus={() => focusAction(true)}
                                   onBlur={() => focusAction(false)}
                            />
                        </div>
                        <Button
                            style={{alignSelf: 'bottom', width: '100%'}}
                            input={{
                                type: 'submit',
                                text$: 'คัดลอก',
                                style: ButtonStyle.textField,
                                // disabled$: false
                            }}
                            output={{
                                click$: () => {
                                    navigator.clipboard.writeText(text ?? '')
                                        .then(() => {
                                            toast.success("Copied to clipboard")
                                        })
                                        .catch(() => {
                                            toast.error("Failed to Copy")
                                        });
                                }
                            }}
                        />
                    </div>
                )
            }

            case TextFieldType.withoutLabelAndNumericOnly: {
                if (disable) {
                    return (
                        <div className='text-field-without-label-input-disable'>
                            <input className={'text-field-input-disable'}
                                   name={input.name}
                                   type={'tel'}
                                   value={text}
                                   required
                                   disabled={true}
                                   placeholder={placeholder}
                                   onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                           event.preventDefault();
                                       }
                                   }}
                                   onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                                   onFocus={() => focusAction(true)}
                                   onBlur={() => focusAction(false)}
                            />
                        </div>
                    )
                } else {
                    return (
                        <div className='text-field-without-label-input'>
                            <input className={'text-field-input'}
                                   name={input.name}
                                   type={'tel'}
                                   value={text}
                                   required
                                   placeholder={placeholder}
                                   onKeyPress={(event) => {
                                       if (!/[0-9]/.test(event.key)) {
                                           event.preventDefault();
                                       }
                                   }}
                                   onChange={event => handleSubjectOrCallbackActionValue(output?.text$, event.target.value)}
                                   onFocus={() => focusAction(true)}
                                   onBlur={() => focusAction(false)}
                            />
                        </div>
                    )
                }
            }
            case TextFieldType.table:
                return (
                    <div className={disable ? 'text-field-new-disable' : 'text-field-new-table'}>
                        <input className={'text-field-input'}
                               name={input.name}
                               type={'text'}
                               autoFocus={input.isAutofocus ?? false}
                               id={input.name}
                               disabled={isDisable}
                               value={text}
                               required
                               placeholder={placeholder}
                               onChange={event => {
                                   handleSubjectOrCallbackActionValue(output?.text$, event.target.value)
                               }}
                               onFocus={(e) => {
                                   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                   focusAction(true)
                                   if (input.isNeedSelectAllWhenFocus === true) {
                                       e.target.select()
                                   }

                               }}
                               onBlur={() => {
                                   handleSubjectOrCallbackAction(output?.onBlur$)
                                   focusAction(false)
                               }}
                        />
                    </div>
                )
            case TextFieldType.search: {
                return (
                    <div className={disable ? 'text-field-new-disable' : 'text-field-new'}>
                        {/*<img src= '/assets/icon/search.png'*/}
                        {/*     className={'search-icon'}*/}
                        {/*     alt="search-icon"/>*/}
                        <SearchRoundedIcon color={'disabled'}/>
                        <input className={'text-field-input'}
                               name={input.name}
                               type={'text'}
                               autoFocus={input.isAutofocus ?? false}
                               id={input.name}
                               disabled={isDisable}
                               value={text}
                               required
                               placeholder={placeholder}
                               onChange={event => {
                                   handleSubjectOrCallbackActionValue(output?.text$, event.target.value)
                               }}
                               onFocus={(e) => {
                                   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                   if (input.isNeedSelectAllWhenFocus === true) {
                                       e.target.select()
                                   }
                                   focusAction(true)
                               }}
                               onBlur={() => {
                                   handleSubjectOrCallbackAction(output?.onBlur$)
                                   focusAction(false)
                               }}
                        />
                    </div>
                )
            }
            default: {
                return (
                    <div className={disable ? 'text-field-new-disable' : 'text-field-new'}>
                        <input className={'text-field-input'}
                               name={input.name}
                               type={'text'}
                               autoFocus={input.isAutofocus ?? false}
                               id={input.name}
                               disabled={isDisable}
                               value={text}
                               required
                               placeholder={placeholder}
                               onChange={event => {
                                   handleSubjectOrCallbackActionValue(output?.text$, event.target.value)
                               }}
                               onFocus={(e) => {
                                   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                   if (input.isNeedSelectAllWhenFocus === true) {
                                       e.target.select()
                                   }
                                   focusAction(true)
                               }}
                               onBlur={() => {
                                   handleSubjectOrCallbackAction(output?.onBlur$)
                                   focusAction(false)
                               }}
                        />
                    </div>
                )
            }
        }
    }
    const inlineErrorComponent = () => {
        if (isError) {
            return <div>
                <div style={{position: 'absolute'}}><Label input={{
                    text$: errorInlineText,
                    style: FontVariants.small,
                    color: ColorNames.errorRed
                }}/></div>
            </div>
        } else {
            return null
        }
    }

    return (
        <div className={'text-field-container'} {...rest}>
            {textFieldLabelComponent()}
            {textFieldComponent()}
            {inlineErrorComponent()}
        </div>
    )
}

