import React from "react";
import {useNavigate} from "react-router-dom";
import {RoutesName} from "../../utility/RoutesName/RoutesName";
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import {Toolbar} from "../../components/base/Toolbar/Toolbar";
import './CreateEventPage.scss'
import {Label} from '../../components/base/Label/Label';
import {FontVariants} from '../../foundation/dls/sizes/generated.fonts';
import {Spacer} from '../../containers/Spacer/Spacer';
import {SizeVariants} from '../../foundation/dls/sizes/generated.sizes';
import {Button} from '../../components/base/Button/Button';
import {
    Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Backdrop, CircularProgress, Snackbar,
    Step,
    StepLabel,
    Stepper,
} from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {CreateEventScene} from './CreateEventScene';
import {
    ColorlibConnector,
    ColorlibStepIcon,
    mapEntryAreaToSummary,
    renderSummaryDivider,
    SummaryRowItem
} from "./CreateEventUltils";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {TableView} from "../../components/base/Table/Table";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import { map, shareReplay, Subject} from "rxjs";
import {isUserLoggedIn} from "../../models/network/LoginNetwork";
import {useEffect, useMemo} from "react";
import {TextField, TextFieldType} from "../../components/base/TextField/TextField";
import {EntryCondition} from "../../models/network/EventNetwork";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const steps = ['เลือกกิจกรรม', 'เลือกผู้เข้าร่วมกิจกรรม', 'สรุปและบันทึก'];

export const CreateEventPage: React.FC<any> = () => {
    const {input, output} = useMemo(() => CreateEventScene(), [])

    const [isLoading, setIsLoading] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [eventID, setEventID] = React.useState('0');
    const [selectedParticipant, setSelectedParticipant] = React.useState<string[]>([]);
    let [events, setEvents] = React.useState<string[][]>([[]])
    let [eventCondition, setEventCondition] = React.useState<EntryCondition | undefined>()
    const [openEventFullAlert, setOpenEventFull] = React.useState(false);
    const [participantAvailable, setParticipantAvailable] = React.useState(0);

    const buttonDisabled$ = useMemo(() => new Subject<boolean>(), [])

    useEffect(() => {
        if (!isUserLoggedIn()) {
            navigate(RoutesName.loginPage)
        }

        const s1 = output.events.subscribe((val) => {
            setEvents(val)
        })

        const s2 = output.eventCondition$.subscribe((val) => {
            setEventCondition(val)
        })

        const s3 =output.navigateToParticipant$.subscribe((data) => {
            if(data.navigate){
                setIsLoading(false)
                setParticipantAvailable(data.available)
                handleComplete()
            }else {
                setIsLoading(false)
                setOpenEventFull(true)
            }
        })

        const s4 = output.navigateToFinishScreen.subscribe(() => {
            setIsLoading(false)
            handleComplete()
        })

        return () => {
            s1.unsubscribe()
            s2.unsubscribe()
            s3.unsubscribe()
            s4.unsubscribe()
        }
    })

    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    let navigate = useNavigate()

    // Step
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    // ---------------------------------------------------------------------------------
    // Render
    // ---------------------------------------------------------------------------------
    const renderStepper = () => {
        return <div className={'stepper-container'}>
            <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector/>}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    }
    // Stage 1 - Select Event
    const renderSelectEventBody = () => {
        return <div className={'card'}>
            <div className={'title-container'}>
                <EditCalendarRoundedIcon/>
                <Spacer size={SizeVariants.$l_spacing}/>
                <Label input={{
                    text$: 'โปรดเลือกกิจกรรมเพื่อเพิ่มผู้เข้าร่วม',
                    style: FontVariants.heading,
                    textAlign: 'center'
                }}/>
            </div>

            <Spacer size={SizeVariants.$xs_spacing}/>
            <TextField
                input={{
                    name: 'name',
                    type: TextFieldType.search,
                    isMandatory: false,
                    text$: input.searchEvent$.pipe(shareReplay(0)),
                    label$: 'ค้นหา',
                    placeholder$: 'ค้นหาโดยใช้ ชื่อกิจกรรม'
                }}
                output={{
                    text$: input.searchEvent$
                }}
            />
            <Spacer size={SizeVariants.$l_spacing}/>

            <TableView
                input={{
                    header: ['วันที่กิจกรรม', 'ชื่อกิจกรรม', 'จำนวนผู้เข้าร่วม'],
                    content$: output.events,
                    isSelectable: true
                }}
                output={{
                    selectableAction: (id: string) => {
                        setEventID(id)
                        setIsLoading(true)
                        input.selectEvent.next(id)
                    }
                }}/>
            <Spacer size={SizeVariants.$xs_spacing}/>
        </div>
    }

    // Stage Two - Select Participant
    const renderParticipant = () => {
        return <div className={'summary'}>
            <div className={'event-summary-card'}>
                <Accordion square={true} TransitionProps={{unmountOnExit: true}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <KeyRoundedIcon/>
                        <Spacer size={SizeVariants.$m_spacing}/>
                        <Label input={{
                            text$: 'เงื่อนไขกิจกรรม ',
                            style: FontVariants.heading,
                            textAlign: 'center'
                        }}/>
                    </AccordionSummary>

                    <AccordionDetails>
                        <div className={'event-condition-card'}>
                            {mapEntryAreaToSummary(eventCondition?.areaConditions)}

                            {renderSummaryDivider()}

                            {SummaryRowItem('AccessTimeRoundedIcon', 'อายุ', ` ${eventCondition?.ageRangeCondition.min}  ถึง  ${eventCondition?.ageRangeCondition.max} `)}

                            {renderSummaryDivider()}

                            {SummaryRowItem('PersonAddAltIcon', 'จำนวนผุ้เข้าร่วม', ` ${events.filter(value => value.includes(eventID))[0][3]}`)}

                            {renderSummaryDivider()}

                            {SummaryRowItem('PersonAddAltRoundedIcon', 'จำนวนผู้เข้าร่วมที่เพิ่ม', ` ${selectedParticipant.length}  คน`)}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <Spacer size={SizeVariants.$s_spacing}/>

            <div className={'card'}>
                <div className={'title-container'}>
                    <PersonAddAltIcon/>
                    <Spacer size={SizeVariants.$l_spacing}/>
                    <Label input={{
                        text$: 'ผู้เข้าร่วมที่ตรงกับเงื่อนไขกิจกรรม ',
                        style: FontVariants.heading,
                        textAlign: 'center'
                    }}/>
                </div>
                <TextField
                    input={{
                        name: 'name',
                        type: TextFieldType.search,
                        isMandatory: false,
                        text$: input.searchParticipant$.pipe(shareReplay(0)),
                        label$: 'ค้นหา',
                        placeholder$: 'ค้นหาโดยใช้ ชื่อ, นามสกุล, เลขบัตรประชาชน, ที่อยู่'
                    }}
                    output={{
                        text$: input.searchParticipant$
                    }}
                />
                <Spacer size={SizeVariants.$m_spacing}/>
                <TableView
                    input={{
                        header: ['วันที่ร่วมกิจกรรม', 'เลขบัตรประชาชน', 'รหัสส่วนตัว', 'ชื่อ-นามสกุล', 'เพศ', 'วัน / เดือน / ปี เกิด', 'ที่อยู่'],
                        content$: output.participants,
                        hasSelect: true
                    }}
                    output={{
                        selectedAction: (ids: string[]) => {
                            setSelectedParticipant(ids)
                            buttonDisabled$.next(ids.length < 1|| ids.length > participantAvailable)
                        }
                    }}/>
                <Spacer size={SizeVariants.$xs_spacing}/>

            </div>
        </div>
    }

    const renderSummary = () => {
        return <div className={'summary'}>
            <div className={'event-summary-card'}>
                <div className={'title-container'}>
                    <InfoRoundedIcon/>
                    <Spacer size={SizeVariants.$l_spacing}/>
                    <Label input={{
                        text$: 'เพิ่มผู้เข้าร่วมเรียบร้อยแล้ว',
                        style: FontVariants.heading,
                        textAlign: 'center'
                    }}/>
                </div>
                <Spacer size={SizeVariants.$s_spacing}/>
                <div className={'summary-card'}>
                    {SummaryRowItem('CalendarTodayRoundedIcon', 'ชื่อกิจกรรม', ` ${events.filter(value => value.includes(eventID))[0][2]} `)}
                    {renderSummaryDivider()}
                    {SummaryRowItem('AccessTimeRoundedIcon', 'วันที่กิจกรรม', ` ${events.filter(value => value.includes(eventID))[0][1]} `)}
                    {renderSummaryDivider()}
                    {SummaryRowItem('PeopleAltRoundedIcon', 'จำนวนผุ้เข้าร่วม', ` ${events.filter(value => value.includes(eventID))[0][3]} `)}
                    {renderSummaryDivider()}
                    {SummaryRowItem('PersonAddAltRoundedIcon', 'จำนวนผู้เข้าร่วมที่เพิ่ม', ` ${selectedParticipant.length}  คน`)}
                </div>
            </div>
            <Spacer size={SizeVariants.$l_spacing}/>

            <div className={'card'}>
                <div className={'title-container'}>
                    <PeopleAltIcon/>
                    <Spacer size={SizeVariants.$l_spacing}/>
                    <Label input={{
                        text$: 'ผู้เข้าร่วมกิจกรรม',
                        style: FontVariants.heading,
                        textAlign: 'center'
                    }}/>
                </div>
                <Spacer size={SizeVariants.$l_spacing}/>
                <TableView
                    input={{
                        header: ['วันที่ร่วมกิจกรรม', 'เลขบัตรประชาชน', 'รหัสส่วนตัว', 'ชื่อ-นามสกุล', 'เพศ', 'วัน / เดือน / ปี เกิด', 'ที่อยู่',
                        ],
                        content$: output.selectedParticipants
                    }}
                    output={{}}/>
                <Spacer size={SizeVariants.$l_spacing}/>
            </div>
        </div>
    }

    const renderMainBody = () => {
        switch (activeStep) {
            case 0:
                return renderSelectEventBody()
            case 1:
                return [renderParticipant(),
                    <Spacer size={SizeVariants.$m_spacing}/>,
                    <Button
                        input={{
                            type: 'button',
                            text$: 'เพิ่มผู้เข้าร่วมที่เลือก',
                            disabled$: buttonDisabled$,
                            isInitialDisabled: true
                        }}
                        output={{
                            click$: () => {
                                setIsLoading(true)
                                input.selectParticipants.next(selectedParticipant)
                            }
                        }}
                    />
                ]
            case 2:
                return [renderSummary(),
                    <Spacer size={SizeVariants.$m_spacing}/>,
                    <Button
                        input={{
                            type: 'button',
                            text$: 'กลับหน้าบ้าน'
                        }}
                        output={{
                            click$: () => {
                                navigate(RoutesName.home)
                            }
                        }}
                    />
                ]
        }
    }

    // ---------------------------------------------------------------------------------
    return (
        <div className={'admin-parent-container'}>
            <Snackbar open={openEventFullAlert} autoHideDuration={6000} onClose={() => setOpenEventFull(false)}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert severity="error" variant="filled" elevation={6}>
                    <AlertTitle>ไม่สามารเพิ่มผู้เข้าร่วม</AlertTitle>
                    เพิ่มผู้เข้าร่วมไม่ได้เนื่องจากกิจกรรมที่เลือก<br/>
                    ถึงขีดจำกัดแล้ว โปรดเพิ่มขีด จำกัด สำหรับ<br/>
                    กิจกรรมหรือนำผู้เข้าร่วมออก
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <div className={'loading-card'}>
                    <CircularProgress/>
                    <Spacer size={SizeVariants.$xxl_spacing}/>
                    <Spacer size={SizeVariants.$l_spacing}/>
                    <Label input={{
                        text$: 'กำลังโหลด',
                        style: FontVariants.subtitleBold,
                    }}/>
                </div>
            </Backdrop>

            <Toolbar input={{title: "สร้างกิจกรรม"}}/>

            <div className={'admin-body'}>

                <Spacer size={SizeVariants.$xxs_spacing}/>

                {renderStepper()}

                <Spacer size={SizeVariants.$m_spacing}/>

                {renderMainBody()}

            </div>

        </div>
    )
    // ---------------------------------------------------------------------------------

}
