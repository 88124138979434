import React, {useEffect} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import {TableProps} from './TableIO';
import {Checkbox, Skeleton, TableFooter, TablePagination} from '@mui/material';
import {Spacer} from '../../../containers/Spacer/Spacer';
import {SizeVariants} from '../../../foundation/dls/sizes/generated.sizes';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import './Table.scss'
import _ from "lodash";

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(even)': {
        backgroundColor: "white",
    },
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(245, 163, 47,0.05)",
        borderRadius: "50px",
        border: '0'
    },
}));

export const TableView: React.FC<TableProps> = ({
                                                    input: {
                                                        header,
                                                        header$,
                                                        content,
                                                        content$,
                                                        hasDownloadOption = false,
                                                        hasSelect = false,
                                                        isSelectable = false,
                                                        resetPage$,
                                                        maxPage$
                                                    },
                                                    output,
                                                    ...rest
                                                }) => {

    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [isNoItemsFound, setIsEmpty] = React.useState<boolean>(false);
    const [currentHeader, setCurrentHeader] = React.useState<string[]>(header ?? []);
    const [currentContent, setCurrentContent] = React.useState<string[][]>(content ?? []);
    const [currentMaxPage, setCurrentMaxPage] = React.useState<number>(-1);

    useEffect(() => {
        if (header$) {
            header$.subscribe((head) => setCurrentHeader(head))
        }
        if (resetPage$) {
            resetPage$.subscribe(() => {
                setPage(0)
            })
        }

        if (maxPage$) {
            maxPage$.subscribe((item) => setCurrentMaxPage(item))
        }

        if (content$) {
            content$.subscribe((val) => {
                console.log("Current val = ", val)
                setCurrentContent(val)
                setIsEmpty(val.length === 0)
                setLoading(false)
            })
        }
    }, [content$, header$, maxPage$, resetPage$])

    const [page, setPage] = React.useState(0);
    const rowsPerPage = 15;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentContent.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        if (output.setPage) {
            setLoading(true)
            setCurrentContent([])
            output.setPage(newPage)
        }
        setPage(newPage);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && output.selectedAction) {
            const newSelected = currentContent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => n[0]);
            setSelected(newSelected);
            output.selectedAction(newSelected)
        } else if (output.selectedAction) {
            setSelected([]);
            output.selectedAction([])
        }
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        if (output.selectedAction) {
            output.selectedAction(newSelected)
        }

    };

    const renderDownloadHeader = () => {
        if (hasDownloadOption) {
            return <TableCell align="center">ดาวน์โหลดรายงาน</TableCell>
        }
    }

    const renderDownloadCell = (id: string) => {
        if (hasDownloadOption) {
            return <TableCell align="center">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: '0 0 auto'}}>
                    <img src={'assets/icon/csv.png'}
                         className={'download-icon'}
                         onClick={() => {
                             if (output.downloadCSV) {
                                 output.downloadCSV(id)
                             }
                         }}
                    />
                    <Spacer size={SizeVariants.$xxl_spacing}/>
                    <img src={'assets/icon/pdf.png'}
                         className={'download-icon'}
                         onClick={() => {
                             if (output.downloadPDF) {
                                 output.downloadPDF(id)
                             }
                         }}
                    />
                </div>

            </TableCell>
        }
    }

    const renderRegeneratePDFCell = (id: string) => {
        if (hasDownloadOption) {
            return <TableCell align="center">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: '0 0 auto'}}>
                    <img src={'assets/icon/pdf.png'}
                         className={'download-icon'}
                         onClick={() => {
                             if (output.regeneratePDF) {
                                 output.regeneratePDF(id)
                             }
                         }}
                    />
                </div>

            </TableCell>
        }
    }

    const renderSelectableHeader = () => {
        if (isSelectable) {
            return <TableCell align="center"></TableCell>
        }
    }

    const renderSelectableCell = () => {
        if (isSelectable) {
            return <TableCell align="right">
                <div className={'table-cell-select'}>
                    <ArrowForwardIosRoundedIcon fontSize={'inherit'} color={'secondary'}/>
                    <Spacer size={SizeVariants.$s_spacing}/>
                </div>
            </TableCell>
        }
    }

    const renderSelectedHeader = () => {
        if (hasSelect) {
            return <TableCell align="center">
                <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < currentContent.length}
                    checked={currentContent.length > 0 && selected.length === currentContent.length}
                    onChange={handleSelectAllClick}
                    sx={{
                        '& .MuiSvgIcon-root': {
                            fontSize: 18,
                            backgroundColor: 'white',
                            borderRadius: '7px',
                            color: 'rgba(245, 163, 47,0.7)',
                        }
                    }}
                />
            </TableCell>
        }
    }

    const renderReGenerateHeader = () => {
        if (hasDownloadOption) {
            return <TableCell align="center">ประมวลผลเอกสารอีกครั้ง</TableCell>
        }
    }

    const handleOnSelectable = (event: React.MouseEvent<unknown>, id: string) => {
        if (isSelectable && output.selectableAction) {
            output.selectableAction(id)
        } else if (hasSelect) {
            handleClick(event, id)
        }
    }

    const renderSkeletonLoadingBody = () => {
        if (isLoading) {
            return _.range(9).map((_n, i) => <StyledTableRow key={i}>
                {_.range(currentHeader.length + 2).map((_n, i) => <TableCell>
                    <Skeleton variant="rounded" height={50}/>
                </TableCell>)}
            </StyledTableRow>)
        }
    }

    const renderNoDataFound = () => {
        if (isNoItemsFound) {
            return <StyledTableRow key={'NO'}>
                {_.range((currentHeader.length - 1) / 2).map((_n, i) => <TableCell>
                    <Skeleton variant="rounded" height={70}/>
                </TableCell>)}
                <TableCell align="center">
                    ไม่พบข้อมูลที่ค้นหา
                </TableCell>
                {_.range((currentHeader.length) / 2).map((_n, i) => <TableCell>
                    <Skeleton variant="rounded" height={70}/>
                </TableCell>)}
            </StyledTableRow>
        }
    }

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    return (
        <TableContainer component={Paper}
                        style={{
                            filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05))',
                            borderRadius: '12px',
                            overflowX: 'auto',
                            flex: '5 0 auto'
                        }}
        >
            <Table stickyHeader sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {renderSelectedHeader()}
                        {currentHeader.map(value => {
                            return <TableCell variant={'head'} align="center">{value}</TableCell>
                        })}
                        {renderDownloadHeader()}
                        {renderSelectableHeader()}
                        {renderReGenerateHeader()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderSkeletonLoadingBody()}
                    {renderNoDataFound()}
                    {(rowsPerPage > 0 && !output.setPage
                            ? currentContent.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : currentContent
                    ).map((rows: any[]) => (
                        <StyledTableRow
                            key={rows[0]}
                            onClick={(event) => handleOnSelectable(event, rows[0])}
                        >
                            {rows.map((value, index) => {
                                if (index != 0) {
                                    return <TableCell key={value + index + Math.random()} align="center">{value}</TableCell>
                                } else {
                                    const isItemSelected = isSelected(rows[0]);
                                    if (hasSelect) {
                                        return <TableCell align="center">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 18,
                                                        backgroundColor: 'white',
                                                        borderRadius: '7px',
                                                        color: 'rgba(245, 163, 47,0.7)',
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                    }
                                }
                            })}

                            {renderDownloadCell(rows[0])}
                            {renderRegeneratePDFCell(rows[0])}
                            {renderSelectableCell()}

                        </StyledTableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{height: 43 * emptyRows}}>
                            <TableCell colSpan={currentHeader.length + 1}/>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                colSpan={currentHeader.length + 1}
                count={currentMaxPage !== -1 ? currentMaxPage * rowsPerPage  : currentContent.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: {
                        'aria-label': 'rows per page',
                    },
                    native: true,
                }}
                onPageChange={handleChangePage}
            />
        </TableContainer>
    );
}
