import React from 'react'
import {FormProps} from './FormIO'

export const Form: React.FC<FormProps> = ({
                                              input,
                                              output,
                                              children,
                                              ...rest
                                          }) => {
    return (
        <form {...rest} onSubmit={event => {
            event.preventDefault()
            output.submit$.next()
        }}>
            {children}
        </form>
    )
}
