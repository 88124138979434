import {Scene} from "../../foundation/mvvm/Scene";
import {BehaviorSubject, Subject} from "rxjs";
import {CreateEventInput, CreateEventOutput} from "./CreateEventIO";
import {CreateEventVM} from './CreateEventVM';

export const CreateEventScene = (): Scene<CreateEventInput, CreateEventOutput, CreateEventVM> => {
    const vm = new CreateEventVM()
    const input: CreateEventInput = {
        searchEvent$: new BehaviorSubject<string>(''),
        searchParticipant$: new BehaviorSubject<string>(''),
        selectEvent: new Subject<string>(),
        selectParticipants: new Subject<string[]>(),
        submit: new Subject<void>(),
    }

    return {
        vm,
        input: input,
        output: vm.transform(input)
    }
}
