export enum RoutesName {
    loginPage = "/login/:status",
    loginNormal = "/login/",
    loginWithError = "/login/session-expire",
    home = "/home",
    createEvent = '/createEvent',
    viewEvent = '/viewEvent',
    viewParticipants = '/viewParticipants'
}

export function RouteWithParams(routeName: RoutesName, params: [string,string][]): string {
    var finalRouteName: string = routeName
    params.forEach(function (value) {
        let paramName = ":" + value.at(0) ?? ''
        let paramValue = value.at(1) ?? ''
        finalRouteName = finalRouteName.replace(paramName, paramValue)
    });
    return finalRouteName
}
