import { BehaviorSubject, Observable } from 'rxjs'
import { useCallback, useEffect, useState } from 'react'
import { skip } from 'rxjs/operators'

export interface Options<T> {
  shouldSetValue?: (oldValue: T | undefined, value: T) => boolean
  unsubscribeOnUnmount?: boolean
}

export function useObservable<T>(observable: Observable<T>, options?: Options<T>): T | undefined {
  let _options = { ...options }
  if (_options?.shouldSetValue == null) {
    _options.shouldSetValue = () => true
  }
  if (_options?.unsubscribeOnUnmount == null) {
    _options.unsubscribeOnUnmount = true
  }

  let init = getInitValue(observable)
  const [value, setValue] = useState<T | undefined>(init)

  const _observable$ = observable instanceof BehaviorSubject ? observable.pipe(skip(0)) : observable

  const subscribe = useCallback(
    () =>
      _observable$.subscribe(v => {
        if (options != null && options.shouldSetValue != null) {
          if (options.shouldSetValue(value, v)) {
            setValue(v)
          }
        } else {
          setValue(v)
        }
      }),
    [_observable$, options, value],
  )

  useEffect(() => {
    const subscription = subscribe()
    return () => {
      if (options?.unsubscribeOnUnmount === true) {
        subscription.unsubscribe()
      }
    }
  })

  return value
}

function getInitValue<T>(observable$: Observable<T> | (Observable<T> & BehaviorSubject<T>)): T | undefined {
  if (observable$ instanceof BehaviorSubject) {
    return observable$.getValue()
  } else {
    return
  }
}
