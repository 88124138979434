import {BounceLoader, HashLoader, MoonLoader, SyncLoader} from 'react-spinners';
import React, {useState} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import {Label} from '../Label/Label';
import {FontVariants} from '../../../foundation/dls/sizes/generated.fonts';
import {ColorNames} from '../../../foundation/dls/theme/generated.colors';
import {Spacer} from '../../../containers/Spacer/Spacer';
import {SizeVariants} from '../../../foundation/dls/sizes/generated.sizes';
import './Loading.scss'
import {LoadingCase, LoadingProps} from './LoadingIO';
import Popup from 'reactjs-popup';
import {color} from 'html2canvas/dist/types/css/types/color';

// ---------------------------------------------------------
// Loading Case
// ---------------------------------------------------------

// ---------------------------------------------------------

export const Loading: React.FC<LoadingProps> = ({
                                                    input: {
                                                        type,
                                                        isLoading
                                                    }
                                                }) => {
    // Variables
    const [isOpen, setOpen] = useState(false)

    isLoading.subscribe({
        next: value => {
            if (value) {
                setOpen(true)
            } else {
                setOpen(false)
            }
        }
    })

    const getClassname = () => {
        switch (type) {
            default:
            case LoadingCase.generic:
                return 'loading'
            case LoadingCase.login:
            case LoadingCase.document:
                return 'blocking-loading'
        }
    }

    // Spinner
    const renderSpinner = () => {
        switch (type) {
            default:
            case LoadingCase.generic:
            case LoadingCase.document:
                return <HashLoader
                    color={'#3E4CFA'}
                    loading={true}
                    cssOverride={{
                        display: "flex",
                        margin: "0 auto"
                    }}
                    speedMultiplier={1.5}
                    size={140}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            case LoadingCase.login:
                return <MoonLoader
                    color={'#3E4CFA'}
                    loading={true}
                    cssOverride={{
                        display: "flex",
                        margin: "0 auto"
                    }}
                    speedMultiplier={1}
                    size={90}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
        }
    }

    // Spacer
    const renderSpacer = () => {
        switch (type) {
            default:
            case LoadingCase.generic:
                return <>
                    <Spacer size={SizeVariants.$xxxl_spacing}/>
                    <Spacer size={SizeVariants.$xl_spacing}/>
                </>
            case LoadingCase.login:
                return <Spacer size={SizeVariants.$xxxl_spacing}/>

        }
    }

    // Text
    const renderText = () => {
        switch (type) {
            default:
            case LoadingCase.generic:
                return <Label input={{
                    text$: '',
                    style: FontVariants.paragraph,
                    color: ColorNames.primary,
                    fontSize: '48px'
                }}/>
            case LoadingCase.document:
                return <Label input={{
                    text$: 'กำลังโหลด',
                    style: FontVariants.paragraph,
                    color: ColorNames.primary,
                    fontSize: '48px'
                }}/>
        }
    }

    return <Popup
        modal
        open={isOpen}
        closeOnDocumentClick={false}
        onClose={() => setOpen(false)}
        className={getClassname()}
    >
        {renderSpinner()}
        {renderSpacer()}
        {renderText()}
    </Popup>

}
// ---------------------------------------------------------
