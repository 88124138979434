import {defer, from, Observable} from "rxjs";
import {AxiosHandler, SIT_URL} from "../../utility/Axios/AxiosHandler";
import {encryptStorage, StorageKey} from "../../utility/storage";
import {AxiosResponse} from "axios";

export class EventNetwork {
    getEventList(): Observable<GetAllEventListResponse> {
        const accessToken = encryptStorage.getItem<string>(StorageKey.accessToken)
        const username = encryptStorage.getItem<string>(StorageKey.username)
        const body = {
            authUsername: username ?? '',
            authentication: accessToken ?? '',
        }
        const req = new Request(SIT_URL + "/events", {
            method: 'POST',
            body: JSON.stringify(body)
        });
        return defer(() => from(fetch(req).then(response => response.json()).then(response => {
                // console.log('------Response from fetch = ' + JSON.stringify(response))
                return response
            })
        ))
    }

    addParticipantsToEvent(eventId: string, participantIds: string[]): Observable<void> {
        const accessToken = encryptStorage.getItem<string>(StorageKey.accessToken)
        const username = encryptStorage.getItem<string>(StorageKey.username)
        const body = {
            authUsername: username ?? '',
            authentication: accessToken ?? '',
            eventRefNo: eventId,
            participantsIDs: participantIds
        }
        const req = new Request(SIT_URL + "/addParticipants", {
            method: 'POST',
            body: JSON.stringify(body)
        });
        return defer(() => from(fetch(req).then(response => response.json()).then(response => {
                // console.log('------Response from fetch = ' + JSON.stringify(response))
                return response
            })
        ))
    }

    addParticipantsToPrefilledEvent(participantIds: string[]): Observable<void> {
        const accessToken = encryptStorage.getItem<string>(StorageKey.accessToken)
        const username = encryptStorage.getItem<string>(StorageKey.username)
        const body = {
            authUsername: username ?? '',
            authentication: accessToken ?? '',
            participantsIDs: participantIds
        }
        const req = new Request(SIT_URL + "/addParticipantsToPrefilledDB", {
            method: 'POST',
            body: JSON.stringify(body)
        });
        return defer(() => from(fetch(req).then(response => response.json()).then(response => {
                // console.log('------Response from fetch = ' + JSON.stringify(response))
                return response
            })
        ))
    }
}

export interface GetAllEventListResponse {
    events: EventModel[]
}

export interface EventModel extends DBModel{
    name: string
    description?: string
    fromDate: Date
    toDate: Date
    participantLimit: number
    participantsCount?: number
    entryCondition?: EntryCondition
    status: EventStatus
    isPrefill?: boolean
}


export interface EntryCondition {
    areaConditions: AreaCondition[]
    ageRangeCondition: AgeRangeCondition
}

export interface AreaCondition {
    type: AreaConditionType
    subAreas: AreaCondition[]
    code: string
    nameTh: string
}

export enum AreaConditionType {
    Province = 0,
    District,
    SubDistrict,
    Village = 999,
}

export interface AgeRangeCondition {
    rangeType: AgeRangeConditionRangeType
    min?: number
    max?: number
}

export enum AgeRangeConditionRangeType {
    ClosedRange,
    OpenedRangeLessThan = -1,
    OpenedRangeMoreThan = 1,
}

export enum EventStatus {
    Draft,
    Published = 10,
    Completed = 20,
}

export interface DBModel {
    _id?: string
    createdDate?: Date
    createdBy?: string
    updatedDate?: Date
    updatedBy?: string
}
