export enum ButtonStyle {
    primary,
    primaryLarge,
    secondary,
    icon,
    transparent,
    transparentWithBlackLabel,
    transparentWithRedLabel,
    redBackground,
    PagerNumberButton,
    PagerNumberButtonFocus,
    textField,
    grow,
    fill,
    slimPrimary,
    slimTransparent,
    miniPrimary,
    slimTransparentBlack
}
