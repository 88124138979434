import {VM} from "../../foundation/mvvm/VM";
import {distinctUntilChanged, map, retry, shareReplay, Subject} from "rxjs";
import {ErrorTracker, trackError} from "../../utility/rx/ErrorTracker";
import {ViewEventPageInput, ViewEventPageOutput} from "./ViewEventIO";
import {
    EventUseCase,
    mapEventModelsToTableDisplay
} from "../../models/usecases/EventUseCase";
import {EventModel} from "../../models/network/EventNetwork";
import {ReportUseCase} from "../../models/usecases/ReportUseCase";

export class ViewEventVM implements VM<ViewEventPageInput, ViewEventPageOutput> {
    constructor(private useCase: EventUseCase = new EventUseCase(),
                private reportUseCase: ReportUseCase = new ReportUseCase(),) {
    }

    transform(input: ViewEventPageInput): ViewEventPageOutput {
        const eventsSubject = new Subject<string[][]>()
        const eventError$ = new ErrorTracker()
        const csvExport$ = new Subject<{ eventName: string, header: string[], body: string[] }>()
        const pdfExport$ = new Subject<{ eventName: string, pdf: string }>()
        const exportError$ = new Subject<void>()
        let allEvents: EventModel[] = []

        function refresh(useCase: EventUseCase) {
            useCase.getEventList().pipe(
                map(item => {
                    return item
                }),
                trackError(eventError$),
                retry(0),
                shareReplay(0),
            ).subscribe((item) => {
                allEvents = item.events
                eventsSubject.next(mapEventModelsToTableDisplay(item.events))
            })
        }

        //on load at first time
        setTimeout(() => {
            refresh(this.useCase)
        }, 10)

        function isEventEmpty(eventID: string) {
            return (allEvents.find((val => val._id == eventID))?.participantsCount ?? 0) == 0
        }

        input.exportCSV$.subscribe((eventId) => {
            if (isEventEmpty(eventId)) {
                exportError$.next()
            } else {
                this.reportUseCase.getReport(eventId, 'CSV').pipe(
                    distinctUntilChanged(),
                    retry(),
                    shareReplay(0),
                    map(val => {
                        return {
                            eventName: `${allEvents.find((val => val._id == eventId))?.name}`,
                            header: val.header ?? [],
                            body: val.body ?? []
                        }
                    })).subscribe(value => csvExport$.next(value))
            }

        })

        input.exportPDF$.subscribe((eventId) => {
            if (isEventEmpty(eventId)) {
                exportError$.next()
            } else {
                this.reportUseCase.getReport(eventId, 'PDF').pipe(
                    distinctUntilChanged(),
                    retry(),
                    shareReplay(0),
                    map(val => {
                        return {
                            eventName: `${allEvents.find((val => val._id == eventId))?.name}`,
                            pdf: val.base64 ?? ''
                        }
                    }),
                    trackError(eventError$),
                ).subscribe(value => pdfExport$.next(value))
            }
        })

        input.regeneratePDF$.subscribe((eventId) => {
            if (isEventEmpty(eventId)) {
                exportError$.next()
            } else {
                this.reportUseCase.regenerateReport(eventId).pipe(
                    distinctUntilChanged(),
                    retry(0),
                    shareReplay(0),
                ).subscribe()
            }
        })

        input.searchEvent$.subscribe((searchText) => {
            if (searchText == '') {
                eventsSubject.next(mapEventModelsToTableDisplay(allEvents))
            } else {
                eventsSubject.next(mapEventModelsToTableDisplay(allEvents.filter(event =>
                    event.name.toLowerCase().includes(searchText.toLowerCase())))
                )
            }
        })

        return {
            events$: eventsSubject.asObservable(),
            exportPDFResult$: pdfExport$.asObservable(),
            exportCSVResult$: csvExport$.asObservable(),
            error$: eventError$,
            exportError$: exportError$.asObservable()
        }
    }
}
