import React from 'react'
import {ButtonProps} from './ButtonIO'
import {useObservableOrValue} from '../../../foundation/hooks/useObservableOrValue'
import {SubjectOrCallback} from '../../../foundation/rx/ObservableOrValue'
import {Subject} from 'rxjs'
import './Button.scss'
import {ButtonStyle} from '../../../foundation/dls/styles/ButtonStyle';
import {Spacer} from '../../../containers/Spacer/Spacer';
import {SizeVariants} from '../../../foundation/dls/sizes/generated.sizes';
import { useEffect } from "react";

export const Button: React.FC<ButtonProps> = ({
                                                  className,
                                                  input,
                                                  output,
                                                  ...rest
                                              }) => {
    const [disabled, setDisabled] = React.useState(input.isInitialDisabled ?? false)
    const style = input.style ?? ButtonStyle.primary
    const spec = getSpec(style)

    const fallbackText = 'Button'
    const text = useObservableOrValue(input.text$) ?? fallbackText

    const fallbackDisabled = false
    // const disabled = useObservableOrValue(input.disabled$) ?? fallbackDisabled
    useEffect(() => {
        if (input.disabled$) {
            input.disabled$.subscribe((isDisabled => setDisabled(isDisabled)))
        }
    })

    const handle = (fn: SubjectOrCallback<void> | undefined) => {
        if (fn == null) return
        if (fn instanceof Subject) {
            fn.next()
        } else {
            fn()
        }
    }

    const renderIcon = () => {
        if (style === ButtonStyle.icon) {
            return <div className={'icon-container'}>
                <img src={'/assets/icon/Upload.png'}
                     className={'icon'}
                     alt={'upload'}/>
                <Spacer size={SizeVariants.$s_spacing}/>
            </div>
        }
    }

    return (
        <button className={spec.className}
                name={input.name}
                type={input.type}
                id={input.id}
                disabled={disabled}
                key={input.key}
                onClick={() => handle(output?.click$)}
                onSubmit={event => {
                    event.preventDefault()
                    handle(output?.submit$)
                }}
                {...rest}>
            {renderIcon()}
            <div className={input.textStyle ?? "button-text-bold"}>{text} </div>
        </button>
    )
}

interface Spec {
    className?: string
}

function getSpec(style: ButtonStyle): Spec {
    switch (style) {
        case ButtonStyle.primary:
            return {
                className: 'primary'
            }
        case ButtonStyle.primaryLarge:
            return {
                className: 'primaryLarge'
            }
        case ButtonStyle.secondary:
            return {
                className: 'secondary'
            }
        case ButtonStyle.icon:
            return {
                className: 'icon-button'
            }
        case ButtonStyle.transparent:
            return {
                className: 'transparent'
            }
        case ButtonStyle.transparentWithBlackLabel:
            return {
                className: 'transparentWithBlackLabel'
            }
        case ButtonStyle.redBackground:
            return {
                className: 'redBackground'
            }
        case ButtonStyle.transparentWithRedLabel:
            return {
                className: 'transparentWithRedLabel'
            }
        case ButtonStyle.PagerNumberButton:
            return {
                className: 'PagerNumberButton'
            }
        case ButtonStyle.PagerNumberButtonFocus:
            return {
                className: 'PagerNumberButtonFocus'
            }
        case ButtonStyle.textField:
            return {
                className: 'textField'
            }
        case ButtonStyle.grow:
            return {
                className: 'grow'
            }
        case ButtonStyle.fill:
            return {
                className: 'fill'
            }
        case ButtonStyle.slimPrimary:
            return {
                className: 'slimPrimary'
            }
        case ButtonStyle.slimTransparent:
            return {
                className: 'slimTransparent'
            }
        case ButtonStyle.miniPrimary:
            return {
                className: 'miniPrimary'
            }
        case ButtonStyle.slimTransparentBlack:
            return {
                className: 'slimTransparentBlack'
            }
    }
}

export enum ButtonTextStyle {
    bold = 'button-text-bold',
    regular = 'button-text-regular',
    small = 'button-text-small'
}
